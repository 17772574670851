import React, { useEffect, useState } from 'react';
import { validateInstallationAddress, NullableAddressLookup, BroadbandReady } from '@sky-tv-group/shared';
import { BlockLoader, ValidatedAddressSuggest } from '@sky-tv-group/components';
import { useField, useForm } from 'react-jeff';
import get from 'lodash/get';
import { addressService, prequalService } from '../../../../../services';
import { FibreDescription } from './FibreDescription';
import { useStore } from '../../../../../store/store';
import { useQuizJourneyState } from '../../../../../provider/QuizJourney';

const AddressFinder = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { updateAddress, updateFibreStatus, address, fibreStatus } = useQuizJourneyState();

  const { isLoadingPrequal } = useStore(s => ({
    isLoadingPrequal: s.isLoadingPrequal,
  }));

  const installationAddress = useField<NullableAddressLookup>({
    defaultValue: { id: '', label: '' },
    required: true,
    validations: [validateInstallationAddress],
  });

  async function prequalify(addressId: string) {
    try {
      setLoading(true);

      const addressDetails = await addressService.getAddressDetail(addressId);
      const tui = get(addressDetails, 'references.tui', undefined);

      if (!tui) {
        throw new Error('Error');
      }

      const prequal = await prequalService.getPrequalDetails(tui);
      if (prequal) {
        if (installationAddress?.value?.id) {
          updateAddress(installationAddress?.value?.id ?? null, installationAddress?.value?.label ?? null);
          updateFibreStatus(prequal.getBroadbandReadyStatus().broadbandReadyStatus);
        }
      }

      setLoading(false);
    } catch (err) {
      console.log('Error');
    }
  }

  useEffect(() => {
    if (
      installationAddress?.value &&
      installationAddress.value.id !== '' &&
      installationAddress.value.id !== address.addressId
    ) {
      prequalify(installationAddress.value.id);
    }
    // eslint-disable-next-line
  }, [installationAddress.value]);

  useEffect(() => {
    if (address.addressId !== null) {
      installationAddress.setValue({
        id: address.addressId,
        label: address.selectedAddress ?? '',
        tui: address.addressId,
      });
    }
  }, []);

  const submit = function() {};
  const form = useForm({
    fields: Object.values(installationAddress),
    onSubmit: submit,
  });

  const resetInstallationAddressField = () => {
    updateAddress(null, null);
    updateFibreStatus(null);

    // setUseGuestPrequal(true);
    installationAddress.reset();
    // setSelectedTuiAddress('');
  };

  let getResponseMessage = () => ({
    title:
      fibreStatus === BroadbandReady.FibreOK
        ? 'Perfect!'
        : fibreStatus === BroadbandReady.FibreFuture
        ? 'Sorry,'
        : fibreStatus === BroadbandReady.FibreNever || fibreStatus === BroadbandReady.Unknown
        ? 'Sorry,'
        : 'Sorry,',
    phrase:
      fibreStatus === BroadbandReady.FibreOK
        ? "Fibre's available at this address."
        : fibreStatus === BroadbandReady.FibreFuture
        ? 'Fibre not available yet'
        : fibreStatus === BroadbandReady.FibreNever || fibreStatus === BroadbandReady.Unknown
        ? 'No Fibre available at this address'
        : 'Fibre availability is unknown at this address',
  });

  let getTitleColor = () => {
    return fibreStatus === BroadbandReady.FibreFuture
      ? 'text-pink'
      : isLoadingPrequal
      ? ''
      : fibreStatus === BroadbandReady.FibreNever || fibreStatus === BroadbandReady.Unknown
      ? 'sky-text-red'
      : 'text-skyGreen';
  };

  return (
    <div>
      <ValidatedAddressSuggest
        addressField={installationAddress}
        formValidation={form}
        id="address"
        addressType="Address"
        addressService={addressService}
        placeholder="Enter your address"
        className="w-full rounded-md h-12"
        disabled={loading}
      />

      {loading ? (
        <BlockLoader />
      ) : (
        <>
          {installationAddress?.value?.id && (
            <div className={`flex flex-col items-center text-center py-6 mt-10`}>
              <div className="flex flex-col">
                {!isLoadingPrequal && (
                  <div>
                    <div className={`text-4xl font-skyBold pb-2 ${getTitleColor()}`}>{getResponseMessage().title}</div>
                    <p className="text-lg text-gray-copy pb-2">{getResponseMessage().phrase}</p>
                  </div>
                )}
                <div className="mt-auto">
                  <FibreDescription clearInstallationAddressField={resetInstallationAddressField} />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AddressFinder;
