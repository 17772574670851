import React, { useEffect } from 'react';
import {
  T_PlanSelector,
  useJSONConfig,
  useProductStore,
  useSplitIO,
  SPLITIO_KEY,
  useBBname,
  productSkuIds,
  categoryIds,
} from '@sky-tv-group/shared';
import { createPortal } from 'react-dom';
import Banner from './Banner';
import Stats from './Stats';
import Carousel from './Carousel';
import Contents from './Contents';
import { useState } from 'react';
import { SUGGESTED_BUNDLE } from '../../../../../../types';
import BroadbandContent from './BroadbandContent';
import { wireUpProductInfo } from '../../../../../../utils/wireUpProductInfo';
import { configService } from '../../../../../../services';

interface Props {
  isShown: boolean;
  subscribed: boolean;
  hide: () => void;
  bundle: SUGGESTED_BUNDLE;
  title: string;
}

const BundleExploreModal = ({ isShown, hide, bundle, subscribed, title }: Props) => {
  const { products } = useProductStore();
  const bbName = useBBname();
  const [planArr, setPlanArr] = useState<T_PlanSelector[]>();
  const config = useJSONConfig(configService);

  /* Only get the ones that we want to display. */
  const [activeProductSKU, setActiveProductSKU] = useState(
    bundle.order.orderProducts.filter(
      p =>
        /* Don't display products below. */
        p.product.sku !== productSkuIds.skyRouter.primary &&
        p.categoryId !== categoryIds.oneOffFee &&
        p.categoryId !== categoryIds.broadbandTechnicianFee &&
        p.categoryId !== categoryIds.monthlyFee &&
        p.categoryId !== categoryIds.broadbandServices &&
        p.categoryId !== categoryIds.broadbandOneOffFee &&
        p.categoryId !== categoryIds.broadbandDevices &&
        p.categoryId !== categoryIds.technicianFee
    )[0].product.sku
  );

  const product = products.find(p => p.sku === activeProductSKU);
  const plan = planArr?.find(p => p.product?.sku === activeProductSKU);
  let planIndex = plan?.index ?? 0;
  const [showOneMonthOnUs] = useSplitIO(SPLITIO_KEY.SKYWEB_BB_ONE_MONTH_ON_US_ON);

  if (plan?.index === 3) planIndex = 1;
  if (plan?.index === 4) planIndex = 0;

  const planContent = plan ? config?.broadband?.acquisition?.speedShowCase.products[planIndex] : null;

  useEffect(() => {
    setPlanArr(wireUpProductInfo([...products], bbName, showOneMonthOnUs));
    // eslint-disable-next-line
  }, [bbName]);

  if (!isShown) return null;

  return createPortal(
    <div className="w-screen h-screen top-0 left-0 bg-white fixed z-1000 overflow-y-scroll overflow-x-hidden">
      <Banner
        hide={hide}
        price={Number(bundle.order.orderTotals.find(t => t.className === 'ot_subtotal')?.value ?? 0)}
        subscribed={subscribed}
      />
      <Stats bundle={bundle} />
      <Carousel
        bundle={bundle}
        title={title}
        activeProductSKU={activeProductSKU}
        setActiveProductSKU={setActiveProductSKU}
      />
      {plan && planContent && <BroadbandContent plan={plan} product={plan.product!} content={planContent} />}
      {!plan && product ? <Contents product={product} /> : <span />}
    </div>,
    document.body
  );
};

export default BundleExploreModal;
