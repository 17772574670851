import React from 'react';
import { useQuizJourneyState } from '../../../../provider/QuizJourney';
import { QuizRoute } from '../../../../types';
import BasicCard from '../../cards/BasicCard';

interface Props {
  step: QuizRoute;
}

const BundleWithSkyStep = ({ step }: Props) => {
  const { updateScore, quizCategories } = useQuizJourneyState();

  if (!updateScore) return <p>Loading</p>;

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-12 w-full">
      {quizCategories
        .filter(c => c.step === step.step)
        .map(c => (
          <BasicCard action={() => updateScore(c.id)} item={c} />
        ))}
    </div>
  );
};

export default BundleWithSkyStep;
