import { categoryIds, T_Product, useFreeProductStore } from '@sky-tv-group/shared';
import React from 'react';
import { Channels } from './Channels';
import { WhatsHot } from './WhatsHot';
import { VERTICAL_VIDEO_PLAYER_URL } from '../../../../../../config';
import { Aspect } from './Aspect';

interface Props {
  product: T_Product;
}

const Contents = ({ product }: Props) => {
  const isPackage = product.categoryName.includes('package');
  const isStarter = product.categoryId === categoryIds.package;
  const showChannelIcons = product.iconImageUrlFromJSON && isPackage;

  return (
    <div className="flex flex-col container row-gap-20 py-14 mx-auto">
      <div className="px-12 md:px-0">
        {product.logoImageUrl ? (
          <img className={isPackage ? 'h-auto w-60' : 'w-100px h-auto'} src={product.logoImageUrl} alt={product.name} />
        ) : (
          <h1 className="sky-h1-black">{product.name}</h1>
        )}
        <p className="mt-6 sky-h3-reg text-navy">{product.explorePopupCopy}</p>
      </div>
      {/* Only show list of channel logos if the card is for a package rather than a single channel.*/}
      {showChannelIcons && (
        <div className="px-12 md:px-0">
          <h4 className="md:sky-h5-reg sky-h5-reg mb-8 text-navy">
            {product.name}
            <span className="md:sky-h5-black sky-h4-black"> channels</span>
          </h4>
          <Channels channelLogos={product.iconImageUrlFromJSON!} />
        </div>
      )}
      {product.popupCards && (
        <div>
          <h4 className="ml-12 md:ml-0 md:sky-h5-reg sky-h5-reg mb-8 text-navy">
            <span className="md:sky-h5-black sky-h4-black">What's hot </span>
            on {product.name}
          </h4>
          <WhatsHot product={product} />
        </div>
      )}
      {isStarter && (
        <div className="flex flex-col md:flex-row bg-gray-cool px-10 py-6">
          <img
            className="h-auto w-full md:w-1/2"
            src="https://static.sky.co.nz/sky/promo-material/sky-go-copy.png"
            alt="sky go promo"
          />
          <img
            className="h-auto w-full md:w-1/2"
            src="https://static.sky.co.nz/sky/promo-material/Sky_Go_TV_Logo_Multi_Devices.png"
            alt="sky go promo image"
          />
        </div>
      )}
      {product.videoUrl && (
        <div>
          <h4 className="ml-12 md:ml-0 md:sky-h5-reg sky-h5-reg mb-8 text-navy">
            <span className="md:sky-h5-black sky-h4-black">Explore </span>
            {product.name}
          </h4>
          <div className="flex-1">
            <Aspect ratio={9 / 16}>
              <iframe
                src={VERTICAL_VIDEO_PLAYER_URL + product?.videoUrl}
                allow="encrypted-media"
                className="w-full h-full"></iframe>
            </Aspect>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contents;
