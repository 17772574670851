import { productSkuIds } from '@sky-tv-group/shared';

export const products: string[] = [
  // TV Packages
  productSkuIds.starter.primary,
  productSkuIds.entertainment.primary,
  productSkuIds.movies.primary,
  productSkuIds.sport.primary,
  // Premium Channels

  // Boxes
  /* Box. */
  productSkuIds.skyBoxCharge.primary,
  /* MySky. */
  productSkuIds.arrowBoxRecording.primary,
  /* New Sky Box.*/
  productSkuIds.arrowBox.primary,

  // WIFI
  productSkuIds.broadbandLightningFastWiFi.primary,
  productSkuIds.broadbandWifi100.primary,
  productSkuIds.broadbandLightningFastWiFiBoost.primary,
  // Booster
  productSkuIds.meshDevice.primary,
];
