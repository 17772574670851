import React from 'react';
import { useQuizJourneyState } from '../../../../provider/QuizJourney';
import { QuizRoute } from '../../../../types';
import SwitchCard from '../../cards/SwitchCard';

interface Props {
  step: QuizRoute;
}

const WifiBoostStep = ({ step }: Props) => {
  const { updateScore, quizCategories, selectedItems } = useQuizJourneyState();

  if (!updateScore) return <p>Loading</p>;

  return (
    <div className="grid grid-cols-1 gap-4 md:gap-6 w-full">
      {quizCategories
        .filter(c => c.step === step.step)
        .map(c => (
          <>
            {/* Hide the rest if booster is needed. */}
            {((!selectedItems?.need_boost && c.id !== 'need_boost') || c.id === 'need_boost') && (
              <SwitchCard action={() => updateScore(c.id)} item={c} />
            )}

            {selectedItems?.need_boost && c.id === 'need_boost' && (
              <div className="sky-bg-gray-light p-3 justify-center">
                <div className="sky-h7-bold text-center">
                  One booster should do the trick, but if you find you need more coverage give us a call.
                </div>
              </div>
            )}
          </>
        ))}
    </div>
  );
};

export default WifiBoostStep;
