import React from 'react';
import { useQuizJourneyState } from '../../../../provider/QuizJourney';
import { QuizRoute } from '../../../../types';
import AddressFinder from './components/AddressFinder';

interface Props {
  step: QuizRoute;
}

const InstallationAddressStep = ({ step }: Props) => {
  const { updateAddress } = useQuizJourneyState();

  if (!updateAddress) return <p>Loading</p>;

  return (
    <div className="flex flex-col w-full">
      <AddressFinder />
    </div>
  );
};

export default InstallationAddressStep;
