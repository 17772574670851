import { productSkuIds } from '@sky-tv-group/shared';
import { QuizItem, QuizStep } from '../types';
import BrowserSvg from './assets/svgs/browser';
import EmailSvg from './assets/svgs/email';
import GamingSvg from './assets/svgs/gaming';
import StreamingSvg from './assets/svgs/streaming';
import VideoCallSvg from './assets/svgs/video-call';
import WorkingFromHomeSvg from './assets/svgs/working-from-home';

export const categories: QuizItem[] = [
  {
    id: 'kids_shows',
    title: 'Kids Shows',
    imageURL:
      'https://www.sky.co.nz/documents/117005/314192/Paw+Patrol+Ready+Race+Rescue+S6+STV1.jpg/86ef9cde-6461-cc9a-b3bd-775edd14b26d?t=1601462584789&download=true',
    tags: ['Actual events', 'Reality TV', 'Docudrama'],
    step: QuizStep.TV,
    weightings: {
      [productSkuIds.starter.primary]: 10,
    },
    secondaryWeightings: {
      [productSkuIds.movies.primary]: 3,
    },
  },
  {
    id: 'comedy',
    title: 'Comedy',
    imageURL:
      'https://sky.co.nz/documents/117005/6905225/South+Side+S2+CC1.jpg/e970387a-affe-07e4-143b-b9efcd9cdaaa?t=1655711554633',
    tags: [],
    step: QuizStep.TV,
    weightings: {
      [productSkuIds.starter.primary]: 10,
    },
    secondaryWeightings: {
      [productSkuIds.movies.primary]: 3,
    },
  },
  {
    id: 'reality',
    title: 'Reality',
    imageURL:
      'https://sky.co.nz/documents/117005/6905225/7+Little+Johnstons+S6+CC1.jpg/0a5aab5b-4515-d2ac-5176-c32dc91fc8b0?t=1655719915957',
    tags: [],
    step: QuizStep.TV,
    weightings: {
      [productSkuIds.starter.primary]: 10,
    },
  },
  {
    id: 'music',
    title: 'Music',
    imageURL:
      'https://sky.co.nz/documents/117005/6905225/MTV+80s+1280x720.jpg/c4512f8f-9dec-d711-9e69-ba85da83f43d?t=1655719520674',
    tags: [],
    step: QuizStep.TV,
    weightings: {
      [productSkuIds.starter.primary]: 10,
    },
  },
  {
    id: 'drama',
    title: 'Quality Drama',
    imageURL:
      'https://sky.co.nz/documents/117005/6905225/Hotel+Portofino+S1+CC1.jpg/f3535105-b03e-7cec-c008-d48a15b7f1a5?t=1655712317739',
    tags: [],
    step: QuizStep.TV,
    weightings: {
      [productSkuIds.entertainment.primary]: 10,
      [productSkuIds.soho.primary]: 3,
    },
  },
  {
    id: 'vintage_and_nostalgia',
    title: 'Vintage & nostalgia',
    imageURL:
      'https://sky.co.nz/documents/117005/6905225/Fresh+Prince+of+Bel+Air+S1+CC1.jpg/c9ef59b8-d598-3e96-6634-e598b81786d5?t=1655711555499',
    tags: [],
    step: QuizStep.TV,
    weightings: {
      [productSkuIds.entertainment.primary]: 10,
    },
    secondaryWeightings: {
      [productSkuIds.movies.primary]: 3,
    },
  },
  {
    id: 'lifestyle',
    title: 'Lifestyle',
    imageURL:
      'https://sky.co.nz/documents/117005/6905225/Gardening+Australia+S31+CC1.jpg/ed38fe6a-c80e-0bef-a6fa-586e74aa8e81?t=1655719915416',
    tags: [],
    step: QuizStep.TV,
    weightings: {
      [productSkuIds.entertainment.primary]: 10,
    },
  },
  {
    id: 'documentary_and_factual',
    title: 'Doco & factual',
    imageURL:
      'https://sky.co.nz/documents/117005/6905225/American+Detective+With+Lt+Kenda+S1+CC1.jpg/d469104f-95ed-8b23-c25a-35d7443e7b34?t=1655712849435',
    tags: [],
    step: QuizStep.TV,
    weightings: {
      [productSkuIds.entertainment.primary]: 10,
      [productSkuIds.arts.primary]: 10,
    },
  },
  {
    id: 'world_news',
    title: 'World news',
    imageURL:
      'https://sky.co.nz/documents/117005/6905225/CNN+Tonight+CC1.jpg/79749fa7-2679-5594-e97e-7fa427ef67ee?t=1655712374742',
    tags: [],
    step: QuizStep.TV,
    weightings: {
      [productSkuIds.entertainment.primary]: 10,
    },
  },
  {
    id: 'movie',
    title: 'Movie',
    imageURL:
      'https://sky.co.nz/documents/117005/6905225/WARNER+BROS+Godzilla+V+Kong+2021+CC1.jpg/9bdcef16-57eb-af4f-cbd9-b71d1fd08982?t=1655712949376',
    tags: [],
    step: QuizStep.TV,
    weightings: {
      [productSkuIds.movies.primary]: 12,
      [productSkuIds.rialto.primary]: 3,
    },
  },
  {
    id: 'sport',
    title: 'Sport',
    imageURL:
      'https://sky.co.nz/documents/117005/6905225/2022-AB_Steinlager-Series-Group-Shot-16x9.jpg/a44d8466-9855-68c4-6ded-4c90b4c889df?t=1655711814724',
    tags: [],
    step: QuizStep.TV,
    weightings: {
      [productSkuIds.sport.primary]: 14,
    },
  },
  {
    id: 'recording',
    title: 'TV Recording',
    caption: 'Would you like to be able to pause, rewind and record live TV?',
    step: QuizStep.BOX_OPTIONS,
    weightings: {
      [productSkuIds.arrowBoxRecording.primary]: 10,
      [productSkuIds.arrowBox.primary]: 10,
    },
  },
  {
    id: 'netflix',
    title: 'Netflix',
    imageURL: '/images/apps/netflix.png',
    step: QuizStep.APPS,
    weightings: {
      [productSkuIds.skyBoxCharge.primary]: 2,
      [productSkuIds.arrowBox.primary]: 2,
    },
    secondaryWeightings: {
      [productSkuIds.movies.primary]: 3,
    },
  },
  {
    id: 'tvnz_od',
    title: 'TVNZ OD',
    imageURL: '/images/apps/tv-nz.png',
    step: QuizStep.APPS,
    weightings: {
      [productSkuIds.skyBoxCharge.primary]: 2,
      [productSkuIds.arrowBox.primary]: 2,
    },
    secondaryWeightings: {
      [productSkuIds.entertainment.primary]: 3,
    },
  },
  {
    id: 'three_now',
    title: 'ThreeNow',
    imageURL: '/images/apps/here-now.png',
    step: QuizStep.APPS,
    weightings: {
      [productSkuIds.skyBoxCharge.primary]: 2,
      [productSkuIds.arrowBox.primary]: 2,
    },
  },
  {
    id: 'disney',
    title: 'Disney+',
    imageURL: '/images/apps/disney-plus.png',
    step: QuizStep.APPS,
    weightings: {
      [productSkuIds.skyBoxCharge.primary]: 2,
      [productSkuIds.arrowBox.primary]: 2,
    },
    secondaryWeightings: {
      [productSkuIds.movies.primary]: 3,
    },
  },
  {
    id: 'neon',
    title: 'Neon',
    imageURL: '/images/apps/neon.png',
    step: QuizStep.APPS,
    weightings: {
      [productSkuIds.skyBoxCharge.primary]: 2,
      [productSkuIds.arrowBox.primary]: 2,
    },
    secondaryWeightings: {
      [productSkuIds.movies.primary]: 3,
    },
  },
  {
    id: 'prime_video',
    title: 'Prime video',
    imageURL: '/images/apps/prime-video.png',
    step: QuizStep.APPS,
    weightings: {
      [productSkuIds.skyBoxCharge.primary]: 2,
      [productSkuIds.arrowBox.primary]: 2,
    },
    secondaryWeightings: {
      [productSkuIds.movies.primary]: 3,
    },
  },
  {
    id: 'spotify',
    title: 'Spotify',
    imageURL: '/images/apps/spotify.png',
    step: QuizStep.APPS,
    weightings: {
      [productSkuIds.skyBoxCharge.primary]: 2,
      [productSkuIds.arrowBox.primary]: 2,
    },
  },

  //Others option added in the quiz app
  {
    id: 'Other_App',
    title: 'Other',
    step: QuizStep.APPS,
    weightings: {
      [productSkuIds.skyBoxCharge.primary]: 2,
      [productSkuIds.arrowBox.primary]: 2,
    },
    secondaryWeightings: {
      [productSkuIds.movies.primary]: 3,
    },
  },
  {
    id: 'none_app',
    title: 'None',
    step: QuizStep.APPS,
    weightings: {},
  },
  {
    id: 'streaming',
    title: 'Streaming',
    icon: StreamingSvg,
    step: QuizStep.WIFI_USE,
    weightings: {
      [productSkuIds.broadbandLightningFastWiFi.primary]: 10,
      [productSkuIds.broadbandWifi100.primary]: 1,
    },
  },
  {
    id: 'wfh',
    title: 'Working from home',
    icon: WorkingFromHomeSvg,
    step: QuizStep.WIFI_USE,
    weightings: {
      [productSkuIds.broadbandLightningFastWiFi.primary]: 10,
      [productSkuIds.broadbandWifi100.primary]: 1,
    },
  },
  {
    id: 'gaming',
    title: 'Gaming',
    icon: GamingSvg,
    step: QuizStep.WIFI_USE,
    weightings: {
      [productSkuIds.broadbandLightningFastWiFi.primary]: 10,
      [productSkuIds.broadbandWifi100.primary]: 3,
    },
  },
  {
    id: 'video_calls',
    title: 'Video calls',
    icon: VideoCallSvg,
    step: QuizStep.WIFI_USE,
    weightings: {
      [productSkuIds.broadbandLightningFastWiFi.primary]: 10,
      [productSkuIds.broadbandWifi100.primary]: 3,
    },
  },
  {
    id: 'emailing',
    title: 'Emailing',
    icon: EmailSvg,
    step: QuizStep.WIFI_USE,
    weightings: {
      [productSkuIds.broadbandLightningFastWiFi.primary]: 3,
      [productSkuIds.broadbandWifi100.primary]: 3,
    },
  },
  {
    id: 'browsing',
    title: 'Browsing',
    icon: BrowserSvg,
    step: QuizStep.WIFI_USE,
    weightings: {
      [productSkuIds.broadbandLightningFastWiFi.primary]: 3,
      [productSkuIds.broadbandWifi100.primary]: 3,
    },
  },
  {
    id: 'up_to_three_people_in_home',
    title: '1-3 people in home',
    icon: BrowserSvg,
    step: QuizStep.MAXIMUM_NUMBER_OF_PEOPLE,
    weightings: {
      [productSkuIds.broadbandLightningFastWiFi.primary]: 1,
      [productSkuIds.broadbandWifi100.primary]: 10,
    },
  },
  {
    id: 'four_plus_people_in_home',
    title: '4+ people in home',
    icon: BrowserSvg,
    step: QuizStep.MAXIMUM_NUMBER_OF_PEOPLE,
    weightings: {
      [productSkuIds.broadbandLightningFastWiFi.primary]: 10,
      [productSkuIds.broadbandWifi100.primary]: 1,
    },
  },
  {
    id: 'need_boost',
    title: 'Do you already know you need a booster in your home?',
    step: QuizStep.WIFI_BOOST,
    weightings: {
      [productSkuIds.broadbandLightningFastWiFiBoost.primary]: 10,
      [productSkuIds.meshDevice.primary]: 2,
    },
  },
  {
    id: 'two_plus_levels',
    title: 'Does your home have 2 or more levels?',
    step: QuizStep.WIFI_BOOST,
    weightings: {
      [productSkuIds.broadbandLightningFastWiFiBoost.primary]: 10,
      [productSkuIds.meshDevice.primary]: 1,
    },
  },
  {
    id: 'four_plus_bedrooms',
    title: 'Does your home have 4+ bedrooms?',
    step: QuizStep.WIFI_BOOST,
    weightings: {
      [productSkuIds.broadbandLightningFastWiFiBoost.primary]: 10,
      [productSkuIds.meshDevice.primary]: 1,
    },
  },
  {
    id: 'two_plus_lounge',
    title: 'Do you have 2 or more living areas?',
    step: QuizStep.WIFI_BOOST,
    weightings: {
      [productSkuIds.broadbandLightningFastWiFiBoost.primary]: 10,
      [productSkuIds.meshDevice.primary]: 1,
    },
  },
];
