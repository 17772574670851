import { createBrowserHistory } from 'history';
import { segment } from '@sky-tv-group/shared';

let history = createBrowserHistory();

history.listen(location => {
  segment.page();
  if ('skus' in (location.state ?? {})) return;
});

export default history;
