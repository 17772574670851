import { bbNameInterface, productSkuIds, T_PlanSelector, T_Product } from '@sky-tv-group/shared';

const getFeature = ({ showOneMonthOnUs }: { showOneMonthOnUs: string | boolean | null }) => {
  return `Your first month ${showOneMonthOnUs ? ' Sky TV, and Sky Broadband ' : ''} ' is on us`;
};

export const wireUpProductInfo = (
  products: T_Product[],
  bbName: bbNameInterface,
  showOneMonthOnUs: string | boolean | null
) => {
  const plan1g = products.find(p => p.sku === productSkuIds.broadbandLightningFastWiFi.primary);
  const plan100m = products.find(p => p.sku === productSkuIds.broadbandWifi100.primary);
  const discount1g = products.find(p => p.sku === productSkuIds.broadbandStarterDiscountOneGig.primary);
  const discount100m = products.find(p => p.sku === productSkuIds.broadbandStarterDiscountHundredMeg.primary);
  const planFibreStarter = products.find(p => p.sku === productSkuIds.broadbandEssnFibre.primary);
  const starter = products.find(p => p.sku === productSkuIds.starter.primary);

  if (!plan1g || !plan100m || !discount1g || !discount100m || !starter || !planFibreStarter) {
    return;
  }

  const data: T_PlanSelector[] = [
    {
      index: 0,
      product: plan1g,
      header: {
        title: bbName.broadbandLightningFastWiFi.label,
        subtitle: plan1g.custom8 ?? '',
      },
      price: {
        name: bbName.broadbandLightningFastWiFi.label,
        fullPrice: `${plan1g.price1}`,
        nonStarterPrice: `${plan1g.priceIncTax}`,
        term: 'On a 12 month contract',
      },
      features: ['869Mbps download/ 506Mbps upload average peak time speeds', 'Your first month is on us'].filter(
        item => {
          if (!showOneMonthOnUs) {
            return !item.includes('Your first month');
          } else {
            return Boolean(item);
          }
        }
      ),
      bundle: false,
      bestPlan: true,
    },
    {
      index: 1,
      product: discount1g,
      header: {
        title: bbName.broadbandLightningFastWiFi.label,
        subtitle: '',
      },
      price: {
        name: bbName.broadbandLightningFastWiFi.label,
        fullPrice: `${starter.priceIncTax + plan1g.price1}`,
        nonStarterPrice: `$${plan1g.priceIncTax}`,
        term: 'On a 12 month contract',
        discount: `${discount1g.priceIncTax}`,
        discountedPrice: `${plan1g.price1}`,
      },
      features: ['869Mbps download/ 506Mbps upload average peak time speeds', getFeature({ showOneMonthOnUs })],
      bundle: true,
      bestPlan: true,
    },
    {
      index: 2,
      product: discount100m,
      header: {
        title: bbName.broadbandWifi100.label,
        subtitle: '',
      },
      price: {
        name: 'Our entry level fibre plan',
        fullPrice: `${starter.priceIncTax + plan100m.price1}`,
        nonStarterPrice: `$${plan100m.priceIncTax}`,
        term: 'On a 12 month contract',
        discount: `${discount100m.priceIncTax}`,
        discountedPrice: `${plan100m.price1}`,
      },
      features: ['300Mbps download/ 100Mbps upload average peak time speeds', getFeature({ showOneMonthOnUs })],
      bundle: true,
    },
    {
      index: 3,
      product: plan100m,
      header: {
        title: bbName.broadbandWifi100.label,
        subtitle: plan100m.custom8 ?? '',
      },
      price: {
        name: 'Our entry level fibre plan',
        fullPrice: `${plan100m.price1}`,
        nonStarterPrice: `${plan100m.priceIncTax}`,
        term: 'On a 12 month contract',
      },
      features: ['300Mbps download/ 100Mbps upload average peak time speeds', 'Your first month is on us'].filter(
        item => {
          if (!showOneMonthOnUs) {
            return !item.includes('Your first month');
          } else {
            return Boolean(item);
          }
        }
      ),
      bundle: false,
    },
    {
      index: 4,
      product: planFibreStarter,
      header: {
        title: bbName.broadbandStarterWiFi.label,
        subtitle: planFibreStarter?.custom8 ?? '',
      },
      price: {
        name: 'Our entry level fibre plan',
        fullPrice: `${planFibreStarter?.price1}`,
        nonStarterPrice: `${60.0}`,
        term: 'On a 12 month contract',
      },
      description:
        'Ideal for emails, online banking, social media, streaming movies, making video calls with ease and browsing the web, on a rolling monthly plan. Includes use of a Sky WiFi Router. ',
      features: ['<strong>Unlimited</strong> fibre up to <strong>50Mbps</strong>'],
      bundle: false,
      imgSrc: 'https://static.sky.co.nz/sky/broadband/img/fibrestarter.png',
      exploreImg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/New/wifi100.png',
    },
  ];
  return data;
};
