import React from 'react';
import Spinner from './Spinner';

const ProcessingAnimation = () => {
  return (
    <div className="flex flex-col items-center text-center transform transition ease-in-out duration-200 delay-300">
      <Spinner />
      <p className="mt-8 items-center sky-h7-reg max-w-md text-xl text-gray-darker">
        Hang on a sec, while our recommendation gnomes find the best match for you!
      </p>
    </div>
  );
};

export default ProcessingAnimation;
