import React, { useEffect } from 'react';
import { useQuizJourneyState } from '../../../../provider/QuizJourney';
import { QuizRoute } from '../../../../types';
import BasicCard from '../../cards/BasicCard';

interface Props {
  step: QuizRoute;
}

const AppsUsedStep = ({ step }: Props) => {
  const { quizCategories, updateScore } = useQuizJourneyState();
  useEffect(() => {
    sessionStorage?.removeItem('upsellclientHeight');
  }, []);

  if (!updateScore) return <p>Loading</p>;

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-6 w-full">
      {quizCategories
        .filter(c => c.step === step.step)
        .map(c => (
          <BasicCard action={() => updateScore(c.id)} item={c} />
        ))}
    </div>
  );
};

export default AppsUsedStep;
