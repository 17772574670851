import React, { createContext, useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { journey } from '../quizData/journey';
import { categories } from '../quizData/categories';
import { products } from '../quizData/products';

import {
  BundleThresholdWeights,
  Journey,
  JourneyType,
  QuizItem,
  QuizStep,
  StepTransitionType,
  SUGGESTED_BUNDLE,
} from '../types';
import {
  BroadbandReady,
  categoryIds,
  productSkuIds,
  QuizEvents,
  QuizInteractionTypes,
  useProductStore,
  useAnalytics,
  couponCodes,
  CreditOTCCode,
  SPLITIO_KEY,
  useSplitIO,
  CreditCampaignCodes,
} from '@sky-tv-group/shared';
import {
  RecommendedArrayStructure,
  RecommendedStructure,
  T_CURATED_PRODUCT_POSTER,
  T_CURATED_QUIZ_ITEM,
} from '@sky-tv-group/shared/src/types';
import { getRecommendedPackage, getRecommendedProducts, sortArray } from '../utils';
import { konakartService } from '../services';
import { useDebouncedEffect } from '../utils/useDebounceEffect';
import { liferayAgent } from '../agent';
import { QuizTitles } from '../constants';

export interface QuizJourneyStateInterface {
  step: QuizStep;
  updateStep: (step: QuizStep) => void;
  quizJourney: Journey | null;
  journeyType: JourneyType | null;
  updateJourney: (type: JourneyType) => void;
  stepTransition: StepTransitionType;
  updateTransition: (type: StepTransitionType) => void;
  fibreStatus: BroadbandReady | null;
  updateFibreStatus: (type: BroadbandReady | null) => void;
  getRecommendedBundles: () => Promise<void | null>;
  bundleThresholds: BundleThresholdWeights | null;
  secondaryBundleThresholds: BundleThresholdWeights | null;
  updateBundleThresholds: (
    key: keyof BundleThresholdWeights,
    value: number,
    type: 'TV' | 'CHANNEL' | 'BOOSTER'
  ) => void;
  address: {
    addressId: string | null;
    selectedAddress: string | null;
  };
  updateSecondaryBundleThresholds: (
    key: keyof BundleThresholdWeights,
    value: number,
    type: 'TV' | 'CHANNEL' | 'BOOSTER'
  ) => void;
  updateAddress: (addressId: string | null, selectedAddress: string | null) => void;
  score: { [x: string]: number } | null;
  updateScore: ((sku: string) => void) | null;
  secondaryScore: { [x: string]: number } | null;
  resetQuiz: (() => void) | null;
  selectedItems: { [x: string]: boolean };
  quizCategories: QuizItem[];
  posters: T_CURATED_PRODUCT_POSTER[];
  suggestedBundles: [SUGGESTED_BUNDLE | null, SUGGESTED_BUNDLE | null, SUGGESTED_BUNDLE | null];
  updateWeightingSystem: ((sku: string, categoryId: string, value: number) => void) | null;
  updateSecondaryWeightingSystem: ((sku: string, categoryId: string, value: number) => void) | null;
  /******************************** FOR TESTING PURPOSES(DYNAMIC WEIGHTING) ********************************* */
  copyWeightingSystemToClipBoard: (() => void) | null;
}

const QuizJourneyStateContext = createContext<QuizJourneyStateInterface>({
  step: QuizStep.INTEREST,
  updateStep: () => null,
  quizJourney: null,
  journeyType: null,
  updateJourney: () => null,
  stepTransition: StepTransitionType.NONE,
  updateTransition: () => null,
  fibreStatus: null,
  updateFibreStatus: () => null,
  address: {
    addressId: null,
    selectedAddress: null,
  },
  updateAddress: () => null,
  score: null,
  secondaryScore: null,
  updateScore: null,
  getRecommendedBundles: async () => {},
  bundleThresholds: null,
  secondaryBundleThresholds: null,
  updateBundleThresholds: () => {},
  updateSecondaryBundleThresholds: () => {},
  selectedItems: {},
  suggestedBundles: [null, null, null],
  posters: [],
  resetQuiz: () => null,
  /******************************** FOR TESTING PURPOSES(DYNAMIC WEIGHTING) ********************************* */
  copyWeightingSystemToClipBoard: null,
  /******************************** FOR TESTING PURPOSES(DYNAMIC WEIGHTING) ********************************* */
  quizCategories: [],
  /******************************** FOR TESTING PURPOSES(DYNAMIC WEIGHTING) ********************************* */
  updateWeightingSystem: null,
  updateSecondaryWeightingSystem: null,
});

const UIQuizJourneyProvider = ({ children }: { children: React.ReactNode }) => {
  const history = useHistory();
  const location = useLocation();
  const [step, setStep] = useState<QuizStep>(QuizStep.INTEREST);
  const [stepTransition, setStepTransition] = useState<StepTransitionType>(StepTransitionType.NONE);
  const [journeyType, setJourneyType] = useState<JourneyType | null>(null);
  const [posters, setPosters] = useState<T_CURATED_PRODUCT_POSTER[]>([]);
  const [suggestedBundles, setSuggestedBundles] = useState<
    [SUGGESTED_BUNDLE | null, SUGGESTED_BUNDLE | null, SUGGESTED_BUNDLE | null]
  >([null, null, null]);
  const [fibreStatus, setFibreStatus] = useState<BroadbandReady | null>(null);
  const [address, setAddress] = useState<{
    addressId: string | null;
    selectedAddress: string | null;
  }>({ addressId: null, selectedAddress: null });
  const [quizJourney, setQuizJourney] = useState<Journey | null>(null);
  const [bundleThresholds, setBundleThresholds] = useState<BundleThresholdWeights>({
    best_value: {
      channelThreshold: 8,
      tvPackagesThreshold: 15,
      boosterThreshold: 1,
    },
    recommended: {
      channelThreshold: 6,
      tvPackagesThreshold: 12,
      boosterThreshold: 1,
    },
    upsell: {
      channelThreshold: 3,
      tvPackagesThreshold: 5,
      boosterThreshold: 1,
    },
  });
  const [secondaryBundleThresholds, setSecondaryBundleThresholds] = useState<BundleThresholdWeights>({
    best_value: {
      channelThreshold: 100,
      tvPackagesThreshold: 100,
      boosterThreshold: 100,
    },
    recommended: {
      channelThreshold: 100,
      tvPackagesThreshold: 100,
      boosterThreshold: 100,
    },
    upsell: {
      channelThreshold: 1,
      tvPackagesThreshold: 1,
      boosterThreshold: 1,
    },
  });

  const [hidePODBundleByPODFlag] = useSplitIO(SPLITIO_KEY.SKYWEB_SKY_POD_FOR_ALL);
  const [hideFusionBoxFromQuizFlag] = useSplitIO(SPLITIO_KEY.SKYWEB_ACQ_HIDE_MNTH_ONEOF_CHRGE);
  const promotedBoxOption = hideFusionBoxFromQuizFlag
    ? productSkuIds.arrowBox.primary
    : productSkuIds.skyBoxCharge.primary;
  const { trackQuiz } = useAnalytics();

  /******************************** FOR TESTING PURPOSES(DYNAMIC WEIGHTING) ********************************* */
  const [quizCategories, setQuizCategories] = useState<QuizItem[]>(
    hideFusionBoxFromQuizFlag
      ? (categories ?? []).map(c => ({
          ...c,
          weightings: {
            ...c?.weightings,
            [productSkuIds.skyBoxCharge.primary]: 0, // Since we are no longer recommending the fusion box in acquisition journey,we should set it's weightage to zero.
          },
        }))
      : categories
  );

  //   So SKY team can test and play with weights themselves and send over what they prefer
  const copyWeightingSystemToClipBoard = () => {
    //    HACK to copy JSON to clipboard
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = JSON.stringify(quizCategories);
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    alert("You've copied this the these weights to your clipboard. Please paste them in an email to Mark or Brady");
  };

  const { products: storeProducts } = useProductStore(s =>
    // Selecting parts of store to bind to root component
    ({
      initializedProductStore: s.initializedProductStore,
      products: s.products,
    })
  );

  const updateWeightingSystem = (sku: string, categoryId: string, value: number) => {
    const _quizCategories = quizCategories;

    const index = _quizCategories.findIndex(c => c.id === categoryId);

    _quizCategories[index].weightings = {
      ..._quizCategories[index].weightings,
      [sku]: value,
    };

    setQuizCategories(_quizCategories);
    setScore(formatScore());
    setSecondaryScore(formatScore(true));
  };

  const updateSecondaryWeightingSystem = (sku: string, categoryId: string, value: number) => {
    const _quizCategories = quizCategories;

    const index = _quizCategories.findIndex(c => c.id === categoryId);

    _quizCategories[index].secondaryWeightings = {
      ..._quizCategories[index].secondaryWeightings,
      [sku]: value,
    };

    setQuizCategories(_quizCategories);
    setScore(formatScore());
    setSecondaryScore(formatScore(true));
  };
  /******************************** END FOR TESTING PURPOSES(DYNAMIC WEIGHTING) ********************************* */

  const [score, setScore] = useState<{ [x: string]: number } | null>(null);
  const [secondaryScore, setSecondaryScore] = useState<{ [x: string]: number } | null>(null);
  const [selectedItems, setSelectedItems] = useState(
    quizCategories.reduce((prev, curr: QuizItem) => {
      prev[curr.id] = false;

      return prev;
    }, {} as { [x: string]: boolean })
  );

  useEffect(() => {
    if (location.pathname !== '/' && location.pathname !== '') {
      history.push({
        pathname: '/',
        search: history.location.search,
      });
    }

    const populateCategoryDataWithLiferayData = async () => {
      const { data: curratedCategories } = await liferayAgent.get<T_CURATED_QUIZ_ITEM[]>(`/tvcards/all`);

      const _quizCategories = categories.reduce((prev: QuizItem[], curr, i) => {
        const category = curratedCategories.find(c => c.id === curr.id);
        if (category) {
          prev.push({ ...categories[i], ...category });
        } else {
          prev.push(categories[i]);
        }

        return prev;
      }, []);

      setQuizCategories(_quizCategories);
    };

    populateCategoryDataWithLiferayData();

    const fetchProductExploreDetailsFromLiferay = async () => {
      const { data } = await liferayAgent.get<T_CURATED_PRODUCT_POSTER[]>(`/bundleproductposters/all`);

      setPosters(data);
    };

    fetchProductExploreDetailsFromLiferay();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSuggestedBundles([null, null, null]);
    if (stepTransition === StepTransitionType.LEAVE) {
      setTimeout(() => setStepTransition(StepTransitionType.ENTER), 150);
      setTimeout(() => setStepTransition(StepTransitionType.NONE), 300);
    }
  }, [stepTransition]);

  useDebouncedEffect(
    async () => {
      await getRecommendedBundles();
    },
    [bundleThresholds, secondaryBundleThresholds],
    3000
  );

  useEffect(() => {
    const eligableRoutes =
      journey
        .find(j => j.journeyType === journeyType)
        ?.routes.filter(r => {
          if (!r.condition?.length) return true;

          let routeIsVisible = true;

          r.condition.forEach(c => {
            if (c.specificToJourney?.indexOf(quizJourney?.journeyType!) === -1) {
              return;
            }

            if (c.type === 'SELECTED' || !routeIsVisible) {
              if (c.answers) {
                const conditonFailed = !c.answers.filter(a => selectedItems[a.id]).length;
                routeIsVisible = conditonFailed;
              }

              if (c.customAnswers && routeIsVisible) {
                const fibreConditon = c.customAnswers.filter(a => a === 'FIBRE_ELIGIBLE');
                if (fibreConditon.length) {
                  const conditonFailed = fibreStatus === BroadbandReady.FibreOK;

                  routeIsVisible = conditonFailed;
                }
              }
            } else if (c.type === 'NOT_SELECTED' || !routeIsVisible) {
              if (c.answers) {
                const conditonFailed = !!c.answers.filter(a => selectedItems[a.id]).length;
                routeIsVisible = conditonFailed;
              }

              if (c.customAnswers && routeIsVisible) {
                const fibreConditon = c.customAnswers.filter(a => a === 'FIBRE_ELIGIBLE');
                if (fibreConditon.length) {
                  const conditonFailed = fibreStatus !== BroadbandReady.FibreOK;

                  routeIsVisible = conditonFailed;
                }
              }
            }
          });

          return routeIsVisible;
        }) ?? [];

    if (journey.find(j => j.journeyType === journeyType)) {
      setQuizJourney({
        journeyType: journey.find(j => j.journeyType === journeyType)?.journeyType!,
        routes: eligableRoutes,
      });
    }
    // eslint-disable-next-line
  }, [journeyType, selectedItems, fibreStatus]);

  useEffect(() => {
    // Since we skip the "number of people in home" question if
    // streaming or wfh are selected during the bb jounrey, we want to
    // reset any possible interactions on these questions
    let _selectedItems = selectedItems;

    _selectedItems['up_to_three_people_in_home'] = false;
    _selectedItems['four_plus_people_in_home'] = false;

    setSelectedItems({ ..._selectedItems });
    setScore(formatScore());
    setSecondaryScore(formatScore(true));
    // eslint-disable-next-line
  }, [journeyType]);

  const formatScore = (isSecondary?: boolean) => {
    return products.reduce((prev, sku: string) => {
      const scoredCategories = quizCategories.filter(c => {
        if (isSecondary) {
          if (c.secondaryWeightings) {
            return c.secondaryWeightings[sku];
          }

          return undefined;
        }

        return c.weightings[sku];
      });

      prev[sku] =
        scoredCategories?.reduce((prevScore, cat) => {
          if (!selectedItems[cat.id]) return prevScore;

          if (isSecondary) {
            if (cat.secondaryWeightings) {
              return (prevScore += cat.secondaryWeightings[sku] ?? 0);
            }

            return prevScore;
          }

          return (prevScore += cat.weightings[sku]);
        }, 0) ?? 0;

      return prev;
    }, {} as { [x: string]: number });
  };

  const updateScore = (sku: string) => {
    let _selectedItems = selectedItems;

    _selectedItems[sku] = !selectedItems[sku];

    // Since we skip the "number of people in home" question if
    // streaming or wfh are selected during the bb jounrey, we want to
    // reset any possible interactions on these questions
    if (sku === 'streaming' || (sku === 'wfh' && quizJourney?.journeyType === JourneyType.BROADBAND_ONLY)) {
      _selectedItems['up_to_three_people_in_home'] = false;
      _selectedItems['four_plus_people_in_home'] = false;
    }

    // If an app is selected and "none" was already selected, reset "none" back to unselected
    if (
      quizCategories.find(c => c.id === sku)?.step === QuizStep.APPS &&
      Object.keys(quizCategories.find(c => c.id === sku)?.weightings ?? {}).length
    ) {
      _selectedItems['none_app'] = false;
    } else if (
      quizCategories.find(c => c.id === sku)?.step === QuizStep.APPS &&
      !Object.keys(quizCategories.find(c => c.id === sku)?.weightings ?? {}).length
    ) {
      categories
        .filter(c => c.step === QuizStep.APPS && c.id !== 'none_app')
        .forEach(c => {
          _selectedItems[c.id] = false;
        });

      _selectedItems['none_app'] = true;
    }

    setSelectedItems({ ..._selectedItems });
    setScore(formatScore());
    setSecondaryScore(formatScore(true));
  };

  useEffect(() => {
    setScore(formatScore());
    setSecondaryScore(formatScore(true));
    // eslint-disable-next-line
  }, []);

  const resetQuiz = () => {
    /* Analytics. */
    trackQuiz(
      QuizEvents.QUIZ_INTERACTION,
      QuizTitles.RECOMMENDATIONS,
      QuizInteractionTypes.BUTTON_CLICK,
      'Retake the quiz',
      '',
      '100%',
      getRecommendedPackage(suggestedBundles),
      getRecommendedProducts(suggestedBundles[1])
    );

    history.push('/');
    setJourneyType(null);
    setQuizJourney(null);
    setSelectedItems(
      quizCategories.reduce((prev, curr: QuizItem) => {
        prev[curr.id] = false;

        return prev;
      }, {} as { [x: string]: boolean })
    );
    setScore(formatScore());
    setSecondaryScore(formatScore(true));
    setAddress({
      addressId: null,
      selectedAddress: null,
    });
    setFibreStatus(null);
    setSuggestedBundles([null, null, null]);
  };

  const getRecommendedBundles = async () => {
    if (!score) return null;

    let includedProducts = [];

    // Only add starter if a TV related journey
    if (journeyType !== JourneyType.BROADBAND_ONLY) {
      includedProducts.push(...[productSkuIds.starter.primary, productSkuIds.skyBoxOTC.primary]);
    }

    // Only add BB extras if a BB related journey
    if (journeyType !== JourneyType.TV_ONLY) {
      includedProducts.push(
        ...[productSkuIds.broadbandSelfInstall.primary, productSkuIds.broadbandSelfInstallOTC.primary]
      );
    }

    let _relevantTvPackages: RecommendedArrayStructure = {
      BEST_VALUE: [],
      RECOMMENDED: [],
      UP_SELL: [],
    };

    let _relevantChannels: RecommendedArrayStructure = {
      BEST_VALUE: [],
      RECOMMENDED: [],
      UP_SELL: [],
    };

    let _promotedBoxes: RecommendedStructure = {
      BEST_VALUE: null,
      RECOMMENDED: null,
      UP_SELL: null,
    };
    let _promotedWifi: RecommendedStructure = {
      BEST_VALUE: null,
      RECOMMENDED: null,
      UP_SELL: null,
    };
    let _promotedRouter: RecommendedStructure = {
      BEST_VALUE: null,
      RECOMMENDED: null,
      UP_SELL: null,
    };
    let _promotedBooster: RecommendedStructure = {
      BEST_VALUE: null,
      RECOMMENDED: null,
      UP_SELL: null,
    };
    let _promotedBundleSkus: RecommendedArrayStructure = {
      BEST_VALUE: [],
      RECOMMENDED: [],
      UP_SELL: [],
    };

    // /**************** GET BEST SUITED BOXES ****************/
    // Leaving commented code in place incase we revert back to old method
    if (journeyType !== JourneyType.BROADBAND_ONLY) {
      /* BEST VALUE is Sky Box if recording is selected, otherwise Sky Pod. */
      // WEB-2848 Sky pod to be default for best value if no recording  selected
      const isRecodringSelected = selectedItems?.recording;
      _promotedBoxes.BEST_VALUE =
        !isRecodringSelected && hidePODBundleByPODFlag ? productSkuIds.skyPod.primary : promotedBoxOption;

      /* NSB is UP SELL. */
      _promotedBoxes.UP_SELL = productSkuIds.arrowBox.primary;

      /* If customer is using 2 or more APPS, RECOMMEND NSB.
      If only 1 APP and doesn't record, RECOMMEND the Pod, otherwise, Sky Box. */
      _promotedBoxes.RECOMMENDED =
        quizCategories
          .filter(c => c.step === QuizStep.APPS)
          .filter(quizItem => {
            const app = selectedItems[quizItem.id];

            return app && !!Object.keys(quizItem.weightings).length;
          }).length >= 2
          ? productSkuIds.arrowBox.primary
          : promotedBoxOption;
    }

    // /**************** END OF GET BEST SUITED BOXES ****************/

    // /**************** GET BEST SUITED WIFI ****************/
    if (journeyType !== JourneyType.TV_ONLY) {
      /* Fibre Starter is BEST VALUE unless there are 4 or more people in the house. */
      const is4PlusPeopleInHome = selectedItems?.four_plus_people_in_home;
      _promotedWifi.BEST_VALUE = is4PlusPeopleInHome
        ? productSkuIds.broadbandWifi100.primary
        : productSkuIds.broadbandEssnFibre.primary;

      /* Fibre Pro is UP SELL. */
      _promotedWifi.UP_SELL = productSkuIds.broadbandLightningFastWiFi.primary;

      _promotedWifi.RECOMMENDED =
        score[productSkuIds.broadbandLightningFastWiFi.primary] > score[productSkuIds.broadbandWifi100.primary]
          ? productSkuIds.broadbandLightningFastWiFi.primary
          : productSkuIds.broadbandWifi100.primary;

      _promotedRouter.BEST_VALUE = productSkuIds.skyRouter.primary;
      _promotedRouter.RECOMMENDED = productSkuIds.skyRouter.primary;
      _promotedRouter.UP_SELL = productSkuIds.skyRouter.primary;

      // Handle booster toggling logic
      if (score[productSkuIds.meshDevice.primary] >= bundleThresholds.best_value.boosterThreshold) {
        _promotedBooster.BEST_VALUE = productSkuIds.meshDevice.primary;

        /* Switch to Boost Plan. */
        switch (_promotedWifi.BEST_VALUE) {
          case productSkuIds.broadbandEssnFibre.primary: {
            _promotedWifi.BEST_VALUE = productSkuIds.broadbandEssnFibBoost.primary;
            break;
          }
          case productSkuIds.broadbandWifi100.primary: {
            _promotedWifi.BEST_VALUE = productSkuIds.broadbandWifi100Boost.primary;
            break;
          }
          case productSkuIds.broadbandLightningFastWiFi.primary: {
            _promotedWifi.BEST_VALUE = productSkuIds.broadbandLightningFastWiFiBoost.primary;
            break;
          }
        }
      }

      if (score[productSkuIds.meshDevice.primary] >= bundleThresholds.recommended.boosterThreshold) {
        _promotedBooster.RECOMMENDED = productSkuIds.meshDevice.primary;

        /* Switch to Boost Plan. */
        switch (_promotedWifi.RECOMMENDED) {
          case productSkuIds.broadbandEssnFibre.primary: {
            _promotedWifi.RECOMMENDED = productSkuIds.broadbandEssnFibBoost.primary;
            break;
          }
          case productSkuIds.broadbandWifi100.primary: {
            _promotedWifi.RECOMMENDED = productSkuIds.broadbandWifi100Boost.primary;
            break;
          }
          case productSkuIds.broadbandLightningFastWiFi.primary: {
            _promotedWifi.RECOMMENDED = productSkuIds.broadbandLightningFastWiFiBoost.primary;
            break;
          }
        }
      }

      if (score[productSkuIds.meshDevice.primary] >= bundleThresholds.upsell.boosterThreshold) {
        _promotedBooster.UP_SELL = productSkuIds.meshDevice.primary;

        /* Switch to Boost Plan. */
        switch (_promotedWifi.UP_SELL) {
          case productSkuIds.broadbandEssnFibre.primary: {
            _promotedWifi.UP_SELL = productSkuIds.broadbandEssnFibBoost.primary;
            break;
          }
          case productSkuIds.broadbandWifi100.primary: {
            _promotedWifi.UP_SELL = productSkuIds.broadbandWifi100Boost.primary;
            break;
          }
          case productSkuIds.broadbandLightningFastWiFi.primary: {
            _promotedWifi.UP_SELL = productSkuIds.broadbandLightningFastWiFiBoost.primary;
            break;
          }
        }
      }
    }
    // /**************** END OF GET BEST SUITED WIFI ****************/

    // /**************** GET RELEVANT TV PACKAGES ****************/
    if (journeyType !== JourneyType.BROADBAND_ONLY) {
      const tvPackages = [
        productSkuIds.entertainment.primary,
        productSkuIds.movies.primary,
        productSkuIds.sport.primary,
      ];

      tvPackages.forEach(p => {
        if (score[p] >= bundleThresholds.best_value.tvPackagesThreshold) {
          _relevantTvPackages.BEST_VALUE.push(p);
        }

        if (score[p] >= bundleThresholds.recommended.tvPackagesThreshold) {
          _relevantTvPackages.RECOMMENDED.push(p);
        }

        if (score[p] >= bundleThresholds.upsell.tvPackagesThreshold) {
          _relevantTvPackages.UP_SELL.push(p);
        }
      });
    }
    // /**************** END OF GET RELEVANT TV PACKAGES ****************/

    // /**************** GET RELEVANT CHANNELS ****************/
    if (journeyType !== JourneyType.BROADBAND_ONLY) {
      // Leaving logic here incase premium channels are added back in
      // Will look like - [productSkuIds.soho.primary, productSkuIds.rialto.primary]
      const channels = [] as string[];

      channels.forEach(p => {
        if (score[p] >= bundleThresholds.best_value.channelThreshold) {
          _relevantChannels.BEST_VALUE.push(p);
        }

        if (score[p] >= bundleThresholds.recommended.channelThreshold) {
          _relevantChannels.RECOMMENDED.push(p);
        }

        if (score[p] >= bundleThresholds.upsell.channelThreshold) {
          _relevantChannels.UP_SELL.push(p);
        }
      });
    }
    // /**************** END OF GET RELEVANT CHANNELS ****************/

    // /**************** CREATE RELEVANT BUNDLE SKU ARRAYS ****************/

    _promotedBundleSkus.BEST_VALUE = [
      ...includedProducts,
      ..._relevantTvPackages.BEST_VALUE,
      ..._relevantChannels.BEST_VALUE,
    ];
    _promotedBundleSkus.RECOMMENDED = [
      ...includedProducts,
      ..._relevantTvPackages.RECOMMENDED,
      ..._relevantChannels.RECOMMENDED,
    ];
    _promotedBundleSkus.UP_SELL = [...includedProducts, ..._relevantTvPackages.UP_SELL, ..._relevantChannels.UP_SELL];
    // Box
    if (_promotedBoxes.BEST_VALUE) {
      _promotedBundleSkus.BEST_VALUE.push(_promotedBoxes.BEST_VALUE);

      if (_promotedBoxes.BEST_VALUE === productSkuIds.skyPod.primary) {
        _promotedBundleSkus.BEST_VALUE.push(productSkuIds.skyPodOneOffFee.primary);
      }
    }

    if (_promotedBoxes.RECOMMENDED) {
      _promotedBundleSkus.RECOMMENDED.push(_promotedBoxes.RECOMMENDED);

      if (_promotedBoxes.RECOMMENDED === productSkuIds.arrowBox.primary) {
        // _promotedBundleSkus.RECOMMENDED.push(productSkuIds.arrowBoxOneOff.primary);
        _promotedBundleSkus.RECOMMENDED.push(productSkuIds.arrowUpfrontBoxFee.primary);
      }
    }

    if (_promotedBoxes.UP_SELL) {
      _promotedBundleSkus.UP_SELL.push(_promotedBoxes.UP_SELL);

      if (_promotedBoxes.UP_SELL === productSkuIds.arrowBox.primary) {
        // _promotedBundleSkus.UP_SELL.push(productSkuIds.arrowBoxOneOff.primary);
        _promotedBundleSkus.UP_SELL.push(productSkuIds.arrowUpfrontBoxFee.primary);
      }
    }

    // Recording
    if (journeyType !== JourneyType.BROADBAND_ONLY) {
      // If TV recording is selected, add My Sky to best value and recommeneded, already included for upsell
      if (
        score[productSkuIds.arrowBoxRecording.primary] > 0 ||
        _promotedBoxes.BEST_VALUE === productSkuIds.arrowBox.primary
      ) {
        if (
          score[productSkuIds.arrowBoxRecording.primary] === 0 &&
          _promotedBoxes.BEST_VALUE === productSkuIds.arrowBox.primary
        ) {
          _promotedBundleSkus.BEST_VALUE.push(productSkuIds.noRecording.primary);
        } else {
          _promotedBundleSkus.BEST_VALUE.push(productSkuIds.arrowBoxRecording.primary);
        }
      }

      if (score[productSkuIds.arrowBoxRecording.primary] > 0) {
        _promotedBundleSkus.RECOMMENDED.push(productSkuIds.arrowBoxRecording.primary);
      } else {
        _promotedBundleSkus.RECOMMENDED.push(productSkuIds.noRecording.primary);
      }

      if (
        score[productSkuIds.arrowBoxRecording.primary] > 0 ||
        _promotedBoxes.UP_SELL === productSkuIds.arrowBox.primary
      ) {
        _promotedBundleSkus.UP_SELL.push(productSkuIds.arrowBoxRecording.primary);
      }
    }

    // /**************** END OF RECORDING ****************/
    // Wifi
    if (_promotedWifi.BEST_VALUE) _promotedBundleSkus.BEST_VALUE.push(_promotedWifi.BEST_VALUE);
    if (_promotedWifi.RECOMMENDED) _promotedBundleSkus.RECOMMENDED.push(_promotedWifi.RECOMMENDED);
    if (_promotedWifi.UP_SELL) _promotedBundleSkus.UP_SELL.push(_promotedWifi.UP_SELL);
    // Router
    if (_promotedRouter.BEST_VALUE) _promotedBundleSkus.BEST_VALUE.push(_promotedRouter.BEST_VALUE);
    if (_promotedRouter.RECOMMENDED) _promotedBundleSkus.RECOMMENDED.push(_promotedRouter.RECOMMENDED);
    if (_promotedRouter.UP_SELL) _promotedBundleSkus.UP_SELL.push(_promotedRouter.UP_SELL);
    // Booster
    if (_promotedBooster.BEST_VALUE) _promotedBundleSkus.BEST_VALUE.push(_promotedBooster.BEST_VALUE);
    if (_promotedBooster.RECOMMENDED) _promotedBundleSkus.RECOMMENDED.push(_promotedBooster.RECOMMENDED);
    if (_promotedBooster.UP_SELL) _promotedBundleSkus.UP_SELL.push(_promotedBooster.UP_SELL);

    // /**************** END OF CREATE RELEVANT BUNDLE SKU ARRAYS ****************/

    /**************** CHECK IF WE HAVE IDENTICAL BUNDLES  ****************/

    const tvPackages = [productSkuIds.entertainment.primary, productSkuIds.movies.primary, productSkuIds.sport.primary];
    const channels = [productSkuIds.rialto.primary, productSkuIds.soho.primary];

    if (
      (JSON.stringify(_promotedBundleSkus.RECOMMENDED.sort()) === JSON.stringify(_promotedBundleSkus.UP_SELL.sort()) ||
        JSON.stringify(_promotedBundleSkus.BEST_VALUE.sort()) ===
          JSON.stringify(_promotedBundleSkus.RECOMMENDED.sort())) &&
      secondaryScore
    ) {
      // /**************** GET SECONDARY RELEVANT TV PACKAGES ****************/

      if (journeyType !== JourneyType.BROADBAND_ONLY) {
        tvPackages.forEach(p => {
          if (secondaryScore[p] >= secondaryBundleThresholds.best_value.tvPackagesThreshold) {
            _promotedBundleSkus.BEST_VALUE.push(p);
          }

          if (secondaryScore[p] >= secondaryBundleThresholds.recommended.tvPackagesThreshold) {
            _promotedBundleSkus.RECOMMENDED.push(p);
          }

          if (secondaryScore[p] >= secondaryBundleThresholds.upsell.tvPackagesThreshold) {
            /* Only UP SELL products not yet in bundle. */
            const isProductInBundle = _promotedBundleSkus.UP_SELL.includes(p);
            if (!isProductInBundle) {
              _promotedBundleSkus.UP_SELL.push(p);
            }
          }
        });

        channels.forEach(p => {
          if (secondaryScore[p] >= secondaryBundleThresholds.best_value.channelThreshold) {
            _promotedBundleSkus.BEST_VALUE.push(p);
          }

          if (secondaryScore[p] >= secondaryBundleThresholds.recommended.channelThreshold) {
            _promotedBundleSkus.RECOMMENDED.push(p);
          }

          if (secondaryScore[p] >= secondaryBundleThresholds.upsell.channelThreshold) {
            _promotedBundleSkus.UP_SELL.push(p);
          }
        });
      }
      // /**************** END OF GET SECONDARY RELEVANT TV PACKAGES ****************/
    }

    // Even if we added secondary scoring and still have the same bundles, UP SELL a product in UP SELL to differentiate. */
    if (JSON.stringify(_promotedBundleSkus.RECOMMENDED.sort()) === JSON.stringify(_promotedBundleSkus.UP_SELL.sort())) {
      /* Initial UP SELL products are Entertainment, Movies, Sport, Rialto and SoHo in order. */
      const upSellProducts = [...tvPackages, ...channels];

      for (var i = 0; i < upSellProducts.length; i++) {
        /* Only UP SELL products not yet in bundle. */
        const p = upSellProducts[i];
        const isProductInBundle = _promotedBundleSkus.UP_SELL.includes(p);
        if (!isProductInBundle) {
          _promotedBundleSkus.UP_SELL.push(p);
          break;
        }
      }
    }

    /* According to Grace, we should always have a BEST VALUE so never give up,. */
    if (
      JSON.stringify(_promotedBundleSkus.BEST_VALUE.sort()) === JSON.stringify(_promotedBundleSkus.RECOMMENDED.sort())
    ) {
      /* Remove MySky if it exists. */
      var array = _promotedBundleSkus.BEST_VALUE; // make a separate copy of the array
      var index = array.indexOf(productSkuIds.arrowBoxRecording.primary);
      if (index !== -1) {
        array.splice(index, 1);
        _promotedBundleSkus.BEST_VALUE = array;
      } else {
        /* Add MySky to RECOMMENDED. */
        _promotedBundleSkus.RECOMMENDED.push(productSkuIds.arrowBoxRecording.primary);
      }
    }

    /**************** END OF CHECK IF WE HAVE IDENTICAL BUNDLES  ****************/
    let BBcoupon = await konakartService.getCoupon(couponCodes.bundlecoupon_BB);
    const buildCart = async () => {
      // BEST VALUE
      //check if BB address is a valid
      if (fibreStatus === BroadbandReady.FibreNever) {
        _promotedBundleSkus.BEST_VALUE = _promotedBundleSkus.BEST_VALUE.filter(
          e =>
            e !== productSkuIds.skyRouter.primary &&
            e !== productSkuIds.meshDevice.primary &&
            e !== productSkuIds.broadbandEssnFibBoost.primary &&
            e !== productSkuIds.broadbandWifi100Boost.primary &&
            e !== productSkuIds.broadbandLightningFastWiFiBoost.primary &&
            e !== productSkuIds.broadbandWifi100.primary &&
            e !== productSkuIds.broadbandEssnFibre.primary &&
            e !== productSkuIds.broadbandSelfInstall.primary &&
            e !== productSkuIds.broadbandSelfInstallOTC.primary &&
            e !== productSkuIds.broadbandLightningFastWiFi.primary
        );
      }
      const bestValueSkus = _promotedBundleSkus.BEST_VALUE.sort(sortArray);
      const _bestValueProducts = storeProducts.filter(
        p => bestValueSkus.includes(p.sku) && p.categoryId !== categoryIds.multiroom
      );
      let bestValueTempId = (await konakartService.getTempCustomerId()).r;
      await konakartService.getOrder(bestValueTempId);
      const bestValueUpdatedOrder = await konakartService.updateOrder(
        bestValueTempId,
        _bestValueProducts.map(p => ({
          productId: p.id,
          quantity: 1,
          finalPriceIncTax: p.priceIncTax,
          downgrade: false,
          inPromotion: false,
          currentlySubscribed: false,
        })),
        [BBcoupon ? BBcoupon.couponCode : '']
      );

      const bestValueUpdatedBundle: SUGGESTED_BUNDLE = {
        order: bestValueUpdatedOrder,
        coupons: [],
      };

      for await (const product of _bestValueProducts) {
        /* Let's just check Starter and Sport since custom2 is just for Starter now.
        And broadband.*/
        if (
          product.sku === productSkuIds.sport.primary ||
          product.sku === productSkuIds.starter.primary ||
          product.categoryId === categoryIds.broadband
        ) {
          /* custom2 can only be used for Starter now. */
          let coupon = undefined;

          /* Handle Sport coupon 01277. */
          if (product.sku === productSkuIds.sport.primary) {
            coupon = await konakartService.getCoupon(couponCodes.oneMonthOnUsWithSport);
            const has01276 = bestValueUpdatedBundle.coupons.find(c => c.couponCode === couponCodes.oneMonthOnUsWOSport);
            if (has01276) {
              bestValueUpdatedBundle.coupons = bestValueUpdatedBundle.coupons.filter(
                c => c.couponCode !== couponCodes.oneMonthOnUsWOSport
              );
            }
          } else if (product.sku === productSkuIds.starter.primary) {
            /* Handle Starter coupon 01276. */
            coupon = await konakartService.getCoupon(product.custom2);
            const has01277 = bestValueUpdatedBundle.coupons.find(
              c => c.couponCode === couponCodes.oneMonthOnUsWithSport
            );
            if (has01277) {
              bestValueUpdatedBundle.coupons = bestValueUpdatedBundle.coupons.filter(
                c => c.couponCode !== couponCodes.oneMonthOnUsWithSport
              );
            }
          } else if (product.categoryId === categoryIds.broadband) {
            /* Handle Broadband coupons 710BB & 711BB. */
            if (
              product.sku === productSkuIds.broadbandLightningFastWiFi.primary ||
              product.sku === productSkuIds.broadbandLightningFastWiFiBoost.primary
            ) {
              coupon = await konakartService.getCoupon(couponCodes.bundlecoupon_BB);
            } else if (
              product.sku === productSkuIds.broadbandWifi100.primary ||
              product.sku === productSkuIds.broadbandWifi100Boost.primary
            )
              coupon = await konakartService.getCoupon(couponCodes.bundlecoupon_BB);
            else if (
              product.sku === productSkuIds.broadbandEssnFibre.primary ||
              product.sku === productSkuIds.broadbandEssnFibBoost.primary
            )
              coupon = await konakartService.getCoupon(couponCodes.bundlecoupon_BB);
          }

          if (coupon) bestValueUpdatedBundle.coupons.push(coupon);
        }
      }

      // RECOMMENDED
      if (fibreStatus === BroadbandReady.FibreNever) {
        _promotedBundleSkus.RECOMMENDED = _promotedBundleSkus.RECOMMENDED.filter(
          e =>
            e !== productSkuIds.skyRouter.primary &&
            e !== productSkuIds.meshDevice.primary &&
            e !== productSkuIds.broadbandEssnFibBoost.primary &&
            e !== productSkuIds.broadbandWifi100Boost.primary &&
            e !== productSkuIds.broadbandLightningFastWiFiBoost.primary &&
            e !== productSkuIds.broadbandWifi100.primary &&
            e !== productSkuIds.broadbandEssnFibre.primary &&
            e !== productSkuIds.broadbandSelfInstall.primary &&
            e !== productSkuIds.broadbandSelfInstallOTC.primary &&
            e !== productSkuIds.broadbandLightningFastWiFi.primary
        );
      }
      const recommendedSkus = _promotedBundleSkus.RECOMMENDED.sort(sortArray);
      const _recommendedProducts = storeProducts.filter(
        p => recommendedSkus.includes(p.sku) && p.categoryId !== categoryIds.multiroom
      );
      let recommendedTempId = (await konakartService.getTempCustomerId()).r;
      await konakartService.getOrder(recommendedTempId);

      const recommendedUpdatedOrder = await konakartService.updateOrder(
        recommendedTempId,
        _recommendedProducts.map(p => ({
          productId: p.id,
          quantity: 1,
          finalPriceIncTax: p.priceIncTax,
          downgrade: false,
          inPromotion: false,
          currentlySubscribed: false,
        })),
        [BBcoupon ? BBcoupon.couponCode : '']
      );

      const recommendedUpdatedBundle: SUGGESTED_BUNDLE = {
        order: recommendedUpdatedOrder,
        coupons: [],
      };

      for await (const product of _recommendedProducts) {
        /* Let's just check Starter and Sport since custom2 is just for Starter now.
        And broadband.*/
        if (
          product.sku === productSkuIds.sport.primary ||
          product.sku === productSkuIds.starter.primary ||
          product.categoryId === categoryIds.broadband
        ) {
          /* custom2 can only be used for Starter now. */
          let coupon = undefined;

          /* Handle Sport coupon 01277. */
          if (product.sku === productSkuIds.sport.primary) {
            //  coupon = await konakartService.getCoupon(couponCodes.oneMonthOnUsWithSport); // to hide 1 month on us
            const has01276 = recommendedUpdatedBundle.coupons.find(
              c => c.couponCode === couponCodes.oneMonthOnUsWOSport
            );
            if (has01276) {
              recommendedUpdatedBundle.coupons = recommendedUpdatedBundle.coupons.filter(
                c => c.couponCode !== couponCodes.oneMonthOnUsWOSport
              );
            }
          } else if (product.sku === productSkuIds.starter.primary) {
            /* Handle Starter coupon 01276. */
            coupon = await konakartService.getCoupon(product.custom2);
            const has01277 = recommendedUpdatedBundle.coupons.find(
              c => c.couponCode === couponCodes.oneMonthOnUsWithSport
            );
            if (has01277) {
              recommendedUpdatedBundle.coupons = recommendedUpdatedBundle.coupons.filter(
                c => c.couponCode !== couponCodes.oneMonthOnUsWithSport
              );
            }
          } else if (product.categoryId === categoryIds.broadband) {
            /* Handle Broadband coupons 710BB & 711BB. */
            if (
              product.sku === productSkuIds.broadbandLightningFastWiFi.primary ||
              product.sku === productSkuIds.broadbandLightningFastWiFiBoost.primary
            ) {
              coupon = await konakartService.getCoupon(couponCodes.bundlecoupon_BB);
            } else if (
              product.sku === productSkuIds.broadbandWifi100.primary ||
              product.sku === productSkuIds.broadbandWifi100Boost.primary
            )
              coupon = await konakartService.getCoupon(couponCodes.bundlecoupon_BB);
          }

          if (coupon) recommendedUpdatedBundle.coupons.push(coupon);
        }
      }

      // UP SELL
      if (fibreStatus === BroadbandReady.FibreNever) {
        _promotedBundleSkus.UP_SELL = _promotedBundleSkus.UP_SELL.filter(
          e =>
            e !== productSkuIds.skyRouter.primary &&
            e !== productSkuIds.meshDevice.primary &&
            e !== productSkuIds.broadbandEssnFibBoost.primary &&
            e !== productSkuIds.broadbandWifi100Boost.primary &&
            e !== productSkuIds.broadbandLightningFastWiFiBoost.primary &&
            e !== productSkuIds.broadbandWifi100.primary &&
            e !== productSkuIds.broadbandEssnFibre.primary &&
            e !== productSkuIds.broadbandSelfInstall.primary &&
            e !== productSkuIds.broadbandSelfInstallOTC.primary &&
            e !== productSkuIds.broadbandLightningFastWiFi.primary
        );
      }
      const upsellSkus = _promotedBundleSkus.UP_SELL.sort(sortArray);
      let _upsellProducts = storeProducts.filter(
        p => upsellSkus.includes(p.sku) && p.categoryId !== categoryIds.multiroom
      );
      let upsellTempId = (await konakartService.getTempCustomerId()).r;
      await konakartService.getOrder(upsellTempId);

      const upsellUpdatedOrder = await konakartService.updateOrder(
        upsellTempId,
        _upsellProducts.map(p => ({
          productId: p.id,
          quantity: 1,
          finalPriceIncTax: p.priceIncTax,
          downgrade: false,
          inPromotion: false,
          currentlySubscribed: false,
        })),
        [BBcoupon ? BBcoupon.couponCode : '']
      );

      const upsellUpdatedBundle: SUGGESTED_BUNDLE = {
        order: upsellUpdatedOrder,
        coupons: [],
      };

      for await (const product of _upsellProducts) {
        /* Let's just check Starter and Sport since custom2 is just for Starter now.
        And broadband.*/

        if (
          product.sku === productSkuIds.sport.primary ||
          product.sku === productSkuIds.starter.primary ||
          product.categoryId === categoryIds.broadband
        ) {
          /* custom2 can only be used for Starter now. */
          let coupon = undefined;

          /* Handle Sport coupon 01277. */
          if (product.sku === productSkuIds.sport.primary) {
            //coupon = await konakartService.getCoupon(couponCodes.oneMonthOnUsWithSport); // to hide 1 month on us
            const has01276 = upsellUpdatedBundle.coupons.find(c => c.couponCode === couponCodes.oneMonthOnUsWOSport);
            if (has01276) {
              upsellUpdatedBundle.coupons = upsellUpdatedBundle.coupons.filter(
                c => c.couponCode !== couponCodes.oneMonthOnUsWOSport
              );
            }
          } else if (product.sku === productSkuIds.starter.primary) {
            /* Handle Starter coupon 01276. */
            coupon = await konakartService.getCoupon(product.custom2);
            const has01277 = upsellUpdatedBundle.coupons.find(c => c.couponCode === couponCodes.oneMonthOnUsWithSport);
            if (has01277) {
              upsellUpdatedBundle.coupons = upsellUpdatedBundle.coupons.filter(
                c => c.couponCode !== couponCodes.oneMonthOnUsWithSport
              );
            }
          } else if (product.categoryId === categoryIds.broadband && fibreStatus !== BroadbandReady.FibreNever) {
            /* Handle Broadband coupons 710BB & 711BB. */
            if (
              product.sku === productSkuIds.broadbandLightningFastWiFi.primary ||
              product.sku === productSkuIds.broadbandLightningFastWiFiBoost.primary
            ) {
              coupon = await konakartService.getCoupon(couponCodes.bundlecoupon_BB);
            } else if (
              product.sku === productSkuIds.broadbandWifi100.primary ||
              product.sku === productSkuIds.broadbandWifi100Boost.primary
            )
              coupon = await konakartService.getCoupon(couponCodes.bundlecoupon_BB);
          }

          if (coupon) upsellUpdatedBundle.coupons.push(coupon);
        }
      }

      if (bestValueUpdatedBundle !== null && recommendedUpdatedBundle !== null && upsellUpdatedBundle !== null) {
        setSuggestedBundles([bestValueUpdatedBundle, recommendedUpdatedBundle, upsellUpdatedBundle]);
      }
    };

    await buildCart();
  };

  return (
    <QuizJourneyStateContext.Provider
      value={React.useMemo(
        () => ({
          step,
          updateStep: (step: QuizStep) => setStep(step),
          score,
          secondaryScore,
          resetQuiz,
          updateScore,
          selectedItems,
          stepTransition,
          updateTransition: (type: StepTransitionType) => setStepTransition(type),
          getRecommendedBundles,
          suggestedBundles,
          copyWeightingSystemToClipBoard,
          quizCategories,
          updateWeightingSystem,
          updateSecondaryWeightingSystem,
          quizJourney,
          journeyType,
          updateJourney: (type: JourneyType) => setJourneyType(type),
          posters,
          bundleThresholds,
          secondaryBundleThresholds,
          updateBundleThresholds: (
            key: keyof BundleThresholdWeights,
            value: number,
            type: 'TV' | 'CHANNEL' | 'BOOSTER'
          ) => {
            let _bundleThresholds = { ...bundleThresholds };
            if (type === 'TV') {
              _bundleThresholds[key].tvPackagesThreshold = value;
            } else if (type === 'CHANNEL') {
              _bundleThresholds[key].channelThreshold = value;
            } else if (type === 'BOOSTER') {
              _bundleThresholds[key].boosterThreshold = value;
            }

            setBundleThresholds(_bundleThresholds);
          },
          updateSecondaryBundleThresholds: (
            key: keyof BundleThresholdWeights,
            value: number,
            type: 'TV' | 'CHANNEL' | 'BOOSTER'
          ) => {
            let _bundleThresholds = { ...secondaryBundleThresholds };
            if (type === 'TV') {
              _bundleThresholds[key].tvPackagesThreshold = value;
            } else if (type === 'CHANNEL') {
              _bundleThresholds[key].channelThreshold = value;
            } else if (type === 'BOOSTER') {
              _bundleThresholds[key].boosterThreshold = value;
            }

            setSecondaryBundleThresholds(_bundleThresholds);
          },
          fibreStatus,
          updateFibreStatus: (status: BroadbandReady | null) => setFibreStatus(status),
          address,
          updateAddress: (addressId: string | null, selectedAddress: string | null) => {
            trackQuiz(
              QuizEvents.QUIZ_INTERACTION,
              QuizTitles.INSTALLATION_ADDRESS,
              QuizInteractionTypes.ADDRESS_ENTERED,
              selectedAddress,
              '',
              '75%',
              '',
              ''
            );
            setAddress({
              addressId,
              selectedAddress,
            });
          },
        }),
        // eslint-disable-next-line
        [
          quizCategories,
          score,
          secondaryScore,
          selectedItems,
          quizJourney,
          journeyType,
          step,
          stepTransition,
          suggestedBundles,
          bundleThresholds,
          secondaryBundleThresholds,
        ]
      )}>
      {children}
    </QuizJourneyStateContext.Provider>
  );
};

const useQuizJourneyState = () => {
  const context = useContext(QuizJourneyStateContext);
  if (!context) {
    throw new Error('useQuizJourneyState must be used within a QuizJourneyStateContext');
  }
  return context;
};

export { UIQuizJourneyProvider, useQuizJourneyState };
