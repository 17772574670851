import React from 'react';
import { useQuizJourneyState } from '../../../../provider/QuizJourney';
import { JourneyType, QuizRoute } from '../../../../types';
import IconCard from '../../cards/IconCard';
import Broadband from './svgs/Broadband';
import TV from './svgs/Tv';
import TVAndBroadband from './svgs/TVAndBroadband';

interface Props {
  step: QuizRoute;
}

const InterestedInStep = ({ step }: Props) => {
  const { updateJourney, journeyType } = useQuizJourneyState();

  return (
    <div className="w-full grid md:inline-grid md:grid-cols-2 gap-4 md:gap-10 mx-auto max-w-2xl">
      <IconCard
        icon={TV}
        label="TV"
        action={() => updateJourney(JourneyType.TV_ONLY)}
        selected={journeyType === JourneyType.TV_ONLY}
      />
      <IconCard
        icon={TVAndBroadband}
        label="TV & Broadband"
        action={() => updateJourney(JourneyType.BOX)}
        selected={journeyType === JourneyType.BOX}
      />
      {/* Disabled until acquisition allows for BB only journeys */}
      {/* <IconCard
        icon={Broadband}
        label="Broadband"
        action={() => updateJourney(JourneyType.BROADBAND_ONLY)}
        selected={journeyType === JourneyType.BROADBAND_ONLY}
      /> */}
    </div>
  );
};

export default InterestedInStep;
