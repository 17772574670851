export const SPLIT_AUTHORIZATION_KEY = process.env.REACT_APP_SPLIT_AUTHORIZATION_KEY!;
export const SPLIT_KEY = 'skyweb';
export const LIFERAY_CONNECT_ENDPOINT = process.env.REACT_APP_LIFERAY_CONNECT_ENDPOINT!;
export const KONAKART_SERVICE_URL = process.env.REACT_APP_KONAKART_ENDPOINT!;
export const ADDRESS_SERVICE_URL = process.env.REACT_APP_ADDRESS_LOOKUP_ENDPOINT!;
export const JSON_CONFIG_URL = process.env.REACT_APP_JSON_CONFIG_URL!;
export const BACKEND_ENDPOINT = process.env.REACT_APP_ENDPOINT!;
export const VERTICAL_VIDEO_PLAYER_URL =
  process.env.VERTICAL_VIDEO_PLAYER_URL ??
  'https://players.brightcove.net/6122285394001/BGHQ9V4jg8_default/index.html?videoId=';
export const AUTH0_LOGOUT_URL = process.env.REACT_APP_AUTH0_LOGOUT_URL!;
export const NAVIGATION_URL = process.env.REACT_APP_LINKS_ENDPOINT!;
export const ACCOUNT_URL = process.env.REACT_APP_ACCOUNT_URL!;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN_MYACCOUNT!;
export const APP_ENVIRONMENT = process.env.REACT_APP_DEPLOY_ENV!;
export const USER_GROUP_SOURCE = process.env.REACT_APP_USER_GROUP_SOURCE!;
export const SENTRY_DENY_URLS = (process.env.REACT_APP_SENTRY_DENY_URLS || '')
  .split(',')
  .filter(s => !!s)
  .map(s => new RegExp(s, 'i'));
