import React from 'react';
import { useQuizJourneyState } from '../../../../provider/QuizJourney';
import { QuizRoute, QuizStep } from '../../../../types';
import cx from 'classnames';
import BundleCard from './components/BundleCard';
import { useEffect } from 'react';
import ProcessingAnimation from '../../ProcessingAnimation';
import { QuizEvents, QuizInteractionTypes, useAnalytics } from '@sky-tv-group/shared';
import { QuizTitles } from '../../../../constants';
import { getRecommendedPackage, getRecommendedProducts } from '../../../../utils';
import { CarouselContainer, PrevArrow, NextArrow } from '@sky-tv-group/components';
import Slider from 'react-slick';

interface Props {
  step: QuizRoute;
}

const RecommendationsStep = ({ step }: Props) => {
  const { getRecommendedBundles, suggestedBundles } = useQuizJourneyState();
  const { trackQuiz } = useAnalytics();

  const wrapperProperties = cx(
    ' w-full-q flex flex-col lg:flex-row lg:justify-center lg:items-stretch sky-text-white delay-300 transform transition ease-in-out duration-200 ',
    {
      'opacity-1 translate-y-0': suggestedBundles[0],
      'opacity-0 translate-y-24': !suggestedBundles[0],
    }
  );

  const slickSettings = {
    autoSlidesToShow: true,
    variableWidth: true,
    infinite: false,
    arrows: false,
    slidesToShow: 4.2,
    dots: false,
    swipeToSlide: true,
    arrowColorClassName: 'text-navy',
    customClassName: ' custom-carousel-bright-blue bundle-carousel',
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsiveBlock: [
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          infinite: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          arrows: true,
          infinite: false,
          slidesToShow: 2.3,
        },
      },
    ],
  };

  let slider = React.createRef<Slider>();

  useEffect(() => {
    const _getRecommendedBundles = async () => {
      await getRecommendedBundles();
    };

    if (!suggestedBundles[0]) {
      _getRecommendedBundles();
    }
  }, []);

  if (!suggestedBundles) {
    return <p>Not reccomendations found</p>;
  }

  if (!suggestedBundles[0]) return <ProcessingAnimation />;

  const numOfBundles = suggestedBundles.filter(b => b?.order.orderProducts.length).length;
  if (step.step === QuizStep.RECOMMENDATIONS) {
    trackQuiz(
      QuizEvents.QUIZ_COMPLETED,
      QuizTitles.RECOMMENDATIONS,
      QuizInteractionTypes.FINISH,
      window.location.href,
      'Quiz Completed',
      '100%',
      getRecommendedPackage(suggestedBundles),
      getRecommendedProducts(suggestedBundles[1])
    );
  }

  return (
    <>
      {/* Desktop. */}
      <div className="sky-text-white hidden lg:grid lg:grid-cols-3 lg:auto-rows-fr lg:gap-6">
        {!!suggestedBundles[0]!.order.orderProducts.length && (
          <BundleCard
            type="BEST_VALUE"
            bundle={suggestedBundles[0]!}
            containerClasses="w-310 bg-sky-primaryBlue rounded-lg col-span-full"
          />
        )}

        {!!suggestedBundles[1]!.order.orderProducts.length && (
          <BundleCard
            type="RECOMMENDED"
            bundle={suggestedBundles[1]!}
            containerClasses="w-310 rounded-lg col-span-full"
            containerStyle="radial-gradient(100% 100% at 0% 0%, #363FBC 0%, #00013A 100%)"
          />
        )}

        {!!suggestedBundles[2]!.order.orderProducts.length && (
          <BundleCard
            type="UP_SELL"
            bundle={suggestedBundles[2]!}
            containerClasses="w-310 rounded-lg col-span-full"
            containerStyle="linear-gradient(90deg, #0D1137 0%, #9000D4 100%)"
          />
        )}
      </div>

      {/* Mobile. */}
      <div className={wrapperProperties + ' block lg:hidden'}>
        <CarouselContainer {...slickSettings} sliderRef={slider}>
          {suggestedBundles.map((p, index) => {
            return (
              <>
                {index === 0 && !!suggestedBundles[0]!.order.orderProducts.length && (
                  <BundleCard
                    type="BEST_VALUE"
                    bundle={suggestedBundles[0]!}
                    containerClasses="w-310 bg-sky-primaryBlue rounded-lg height-inherit"
                  />
                )}

                {index === 1 && !!suggestedBundles[1]!.order.orderProducts.length && (
                  <BundleCard
                    type="RECOMMENDED"
                    bundle={suggestedBundles[1]!}
                    containerClasses="w-310 rounded-lg height-inherit"
                    containerStyle="radial-gradient(100% 100% at 0% 0%, #363FBC 0%, #00013A 100%)"
                  />
                )}

                {index === 2 && !!suggestedBundles[2]!.order.orderProducts.length && (
                  <BundleCard
                    type="UP_SELL"
                    bundle={suggestedBundles[2]!}
                    containerClasses="w-310 rounded-lg height-inherit"
                    containerStyle="linear-gradient(90deg, #0D1137 0%, #9000D4 100%)"
                  />
                )}
              </>
            );
          })}
        </CarouselContainer>
      </div>
    </>
  );
};

export default RecommendationsStep;
