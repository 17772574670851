import { JourneyType, QuizRoute, QuizStep } from '../types';
import AppsUsedStep from '../components/quiz/steps/AppsUsedStep';
import BundleWithSkyStep from '../components/quiz/steps/BundleWithSkyStep';
import InstallationAddressStep from '../components/quiz/steps/InstallationAddressStep';
import LikeToWatchStep from '../components/quiz/steps/LikeToWatchStep';
import WifiBoostStep from '../components/quiz/steps/WifiBoostStep';
import WifiUsageStep from '../components/quiz/steps/WifiUsageStep';
import RecommendationsStep from '../components/quiz/steps/RecommendationsStep';
import MaximumNumberOfPeopleStep from '../components/quiz/steps/MaximumNumberOfPeopleStep';
import { categories } from './categories';

export const routes: QuizRoute[] = [
  {
    slug: '/installation-address',
    title: 'What’s your installation address?',
    caption: 'To help us choose the right package we need to know your address',
    step: QuizStep.INSTALLATION_ADDRESS,
    component: InstallationAddressStep,
  },
  {
    slug: '/tv',
    title: 'What TV do you like to watch?',
    step: QuizStep.TV,
    component: LikeToWatchStep,
  },
  {
    slug: '/apps',
    title: 'Which Apps do you currently use?',
    caption: 'This helps us choose the right Sky Box for you',
    step: QuizStep.APPS,
    component: AppsUsedStep,
    mandatory: true,
  },
  {
    slug: '/bundle-with-sky',
    title: 'What do you want to bundle with Sky TV?',
    step: QuizStep.BUNDLE,
    component: BundleWithSkyStep,
  },
  {
    slug: '/wifi-use',
    title: 'What is WiFi being used for in your home?',
    step: QuizStep.WIFI_USE,
    component: WifiUsageStep,
    mandatory: true,
    condition: [
      {
        type: 'SELECTED',
        customAnswers: ['FIBRE_ELIGIBLE'],
        specificToJourney: [JourneyType.BROADBAND_ONLY, JourneyType.BOX],
      },
    ],
  },
  {
    slug: '/maximum-number-of-people',
    title: "What's the maximum number of people in your household using the internet at any one time?",
    step: QuizStep.MAXIMUM_NUMBER_OF_PEOPLE,
    component: MaximumNumberOfPeopleStep,
    mandatory: true,
    condition: [
      {
        type: 'SELECTED',
        answers: [categories.find(c => c.id === 'wfh')!, categories.find(c => c.id === 'streaming')!],
        specificToJourney: [JourneyType.BROADBAND_ONLY],
      },
      {
        type: 'SELECTED',
        customAnswers: ['FIBRE_ELIGIBLE'],
        specificToJourney: [JourneyType.BROADBAND_ONLY, JourneyType.BOX],
      },
    ],
  },
  {
    slug: '/wifi-boost',
    title: 'Does you WiFi need a boost?',
    caption: 'You may need a Sky WiFi Booster with our Sky WiFi Router',
    step: QuizStep.WIFI_BOOST,
    component: WifiBoostStep,
    condition: [
      {
        type: 'SELECTED',
        customAnswers: ['FIBRE_ELIGIBLE'],
        specificToJourney: [JourneyType.BROADBAND_ONLY, JourneyType.BOX],
      },
    ],
  },
  {
    slug: '/recommended',
    title: 'Here are our recommended packages for you',
    step: QuizStep.RECOMMENDATIONS,
    component: RecommendationsStep,
  },
];
