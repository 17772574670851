import { Button } from '@sky-tv-group/components';
import ReactTooltip from 'react-tooltip';

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuizJourneyState } from '../../../provider/QuizJourney';
import { JourneyType, QuizRoute, QuizStep, StepTransitionType } from '../../../types';
import { useEffect } from 'react';
import { QuizEvents, QuizInteractionTypes, useAnalytics, BroadbandReady } from '@sky-tv-group/shared';
import { QuizInteractionDetails } from '../../../constants';
import { getTitle } from '../../../utils';
interface Props {
  step: QuizRoute;
}

const QuizNavigation = ({ step }: Props) => {
  const history = useHistory();
  const { trackQuiz } = useAnalytics();
  const {
    quizJourney,
    updateStep,
    updateTransition,
    address,
    quizCategories,
    selectedItems,
    fibreStatus,
  } = useQuizJourneyState();
  const [showNav, setShowNav] = useState(false);
  const currentStep = quizJourney?.routes.findIndex(s => s.slug === step.slug);

  const calculateProgress = (): number => {
    if (currentStep === undefined || currentStep === -1) {
      return 0;
    }

    // VS CODE Formatter breaking inline formatting
    const index = (currentStep ?? 0) + 1;
    const length = quizJourney?.routes?.length ?? 1;
    return Math.round((index / length) * 100);
  };

  const IsVisted = (route: QuizRoute): boolean => {
    const routeStep = quizJourney?.routes.findIndex(s => s.slug === route.slug);

    if (currentStep === undefined || routeStep === undefined) return false;

    return routeStep <= currentStep;
  };

  const getNavigationRoute = () => {
    if (!quizJourney?.routes.length) return '/';

    if (currentStep === undefined || currentStep === -1) {
      return quizJourney.routes[0].slug;
    } else {
      // Validate installation date before continuing
      const isInstallationStep = quizJourney?.routes[currentStep].step === QuizStep.INSTALLATION_ADDRESS;

      if (isInstallationStep && address.addressId == null) return null;

      // If a mandatory step, ensure an option is selected ebfore continuing
      if (quizJourney?.routes[currentStep].mandatory) {
        const atLeastOneAppHasBeenChosen = !!quizCategories
          .filter(c => c.step === step.step)
          .filter(quizItem => {
            const app = selectedItems[quizItem.id];

            return app && (!!Object.keys(quizItem.weightings).length || quizItem.id.indexOf('none_') !== -1);
          }).length;

        if (!atLeastOneAppHasBeenChosen) return null;
      }

      return quizJourney?.routes[currentStep + 1]?.slug ?? null;
    }
  };

  useEffect(() => {
    setTimeout(() => setShowNav(quizJourney !== null), 10);
  }, [quizJourney]);

  const getQuizSelection = (step: QuizStep | undefined, quizJourney: JourneyType) => {
    switch (step) {
      case QuizStep.INTEREST:
        return quizJourney === JourneyType.TV_ONLY
          ? 'tv'
          : quizJourney === JourneyType.BROADBAND_ONLY
          ? 'broadband'
          : 'tv&broadband';
      default:
        return Object.keys(selectedItems).filter(key => {
          if (selectedItems[key] === true) return key;
        });
    }
  };

  if (!quizJourney) return null;

  return (
    <div
      style={{ boxShadow: '0px 1px 3px 1px rgba(63, 63, 63, 0.15), 0px 1px 2px rgba(63, 63, 63, 0.3)' }}
      className={`fixed bottom-0 left-0 z-20 w-full p-4 bg-white rounded-tl-lg rounded-tr-lg flex flex-col lg:flex-row items-center transform transition ease-in-out duration-300 ${
        showNav ? 'translate-y-0' : ' translate-y-full'
      }
      ${step.step === QuizStep.RECOMMENDATIONS ? 'justify-center' : 'justify-between'}
      `}>
      <Button
        onClick={() => {
          /* Analytics. */
          trackQuiz(
            QuizEvents.QUIZ_INTERACTION,
            getTitle(step.step),
            QuizInteractionTypes.BUTTON_CLICK,
            QuizInteractionDetails.BACK,
            '',
            calculateProgress() + '%',
            '',
            ''
          );
          return step.step === QuizStep.INTEREST ? null : history.goBack();
        }}
        disabled={step.step === QuizStep.INTEREST}
        variant="secondary"
        colorMode="pure"
        className="min-w-40  bg-transparent hidden lg:inline-block">
        Back
      </Button>

      {/* PROGRESS BAR */}
      {step.step !== QuizStep.RECOMMENDATIONS && (
        <div className="flex flex-col items-center justify-center">
          <p className="text-skyGreen text-5xl mb-1 leading-none">
            {calculateProgress()}
            <span className="text-sm">%</span>
          </p>
          <p className="text-skyGreen text-sm mb-2">Total progress</p>

          <div className="relative w-72 rounded-full h-5 bg-white border border-gray-copy overflow-hidden flex mb-2">
            {quizJourney?.routes.map(r => (
              <>
                {IsVisted(r) && (
                  <ReactTooltip
                    id={`${r.slug}-tooltip`}
                    effect="solid"
                    delayUpdate={500}
                    delayHide={250}
                    delayShow={250}>
                    <button onClick={() => history.push(r.slug)} className="hover:underline">
                      {r.title}
                    </button>
                  </ReactTooltip>
                )}
                <span
                  data-tip
                  data-for={`${r.slug}-tooltip`}
                  onClick={() => (!IsVisted(r) ? null : history.push(r.slug))}
                  className={`h-full bg-midnight flex-1 ${
                    IsVisted(r) ? 'bg-skyGreen hover:bg-skyGreenDark cursor-pointer' : 'bg-transparent'
                  } ${
                    currentStep == quizJourney?.routes.findIndex(s => s.slug === r.slug) ? 'rounded-r-full' : undefined
                  }`}
                />
              </>
            ))}
          </div>
          <p className="text-gray-copy text-sm">
            {(currentStep ?? 0) + 2} / {quizJourney.routes?.length + 1 ?? 1} steps completed
          </p>
        </div>
      )}

      <div
        className={`inline-flex w-full lg:w-auto mt-3 lg:mt-0 flex-row items-center ${
          step.step === QuizStep.RECOMMENDATIONS ? 'justify-center' : 'justify-between'
        } `}>
        <Button
          onClick={() => {
            /* Analytics. */
            trackQuiz(
              QuizEvents.QUIZ_INTERACTION,
              getTitle(step.step),
              QuizInteractionTypes.BUTTON_CLICK,
              QuizInteractionDetails.BACK,
              '',
              calculateProgress() + '%',
              '',
              ''
            );
            return step.step === QuizStep.INTEREST ? null : history.goBack();
          }}
          disabled={step.step === QuizStep.INTEREST}
          variant="secondary"
          colorMode="pure"
          className="min-w-40 bg-transparent inline-block lg:hidden">
          Back
        </Button>

        {step.step !== QuizStep.RECOMMENDATIONS && (
          <Button
            onClick={() => {
              /* Analytics. */
              trackQuiz(
                QuizEvents.QUIZ_INTERACTION,
                '',
                QuizInteractionTypes.BUTTON_CLICK,
                QuizInteractionDetails.CONTINUE,
                getQuizSelection(step.step, quizJourney.journeyType),
                calculateProgress() + '%',
                '',
                ''
              );

              updateTransition(StepTransitionType.LEAVE);

              setTimeout(() => {
                history.push({
                  pathname: getNavigationRoute() ?? '',
                  search: history.location.search,
                });

                // Update step index in journey provider
                const nextStep = quizJourney?.routes.find(s => s.slug === getNavigationRoute());
                if (nextStep) updateStep(nextStep.step);
              }, 250);
            }}
            variant="primary"
            colorMode={getNavigationRoute() === null ? 'disabled' : 'pure'}
            disabled={getNavigationRoute() === null}
            className="ml-auto min-w-40">
            {fibreStatus !== BroadbandReady.FibreNever
              ? QuizInteractionDetails.CONTINUE
              : QuizInteractionDetails.WITHOUT_BB}
          </Button>
        )}
      </div>
    </div>
  );
};

export default QuizNavigation;
