import { Checked, Unchecked } from '@sky-tv-group/components';
import cx from 'classnames';
import React, { useState } from 'react';

interface Props {
  icon: ({ active }: { active: boolean }) => JSX.Element;
  label: string;
  action: Function;
  selected: boolean;
  caption?: string;
  height?: string;
}

const IconCard = ({ icon, label, action, selected, caption, height = 'h-80' }: Props) => {
  const [active, toggleActive] = useState(false);
  const labelClasses = cx({ 'skewed-purple-text': active || selected, 'text-black': !active && !selected });

  return (
    <div
      className={`w-full ${height} border border-gray-lightBorder rounded-lg flex flex-col items-center bg-white justify-center relative cursor-pointer transition-all ease-in-out p-6`}
      onClick={() => action()}
      onMouseOver={() => toggleActive(true)}
      onMouseOut={() => toggleActive(false)}>
      <div className="absolute p-2 top-0 right-0">{selected ? <Checked /> : <Unchecked />}</div>
      <div className="mb-4 flex items-center justify-center h-32 w-32 rounded-full bg-gray-light">
        <div className="w-16">{icon({ active: active || selected })}</div>
      </div>
      <h3 className={`sky-h3-bold transition ease-in-out duration-300 ${labelClasses}`}>{label}</h3>
      {caption && (
        <div className="w-full mt-3 flex items-start">
          <p className="text-xs text-center" dangerouslySetInnerHTML={{ __html: caption }} />
        </div>
      )}
    </div>
  );
};

export default IconCard;
