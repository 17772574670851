import { headerChannelInterceptor } from '@sky-tv-group/shared';
import axios, { AxiosRequestConfig } from 'axios';
import { getClient } from '../auth0/store';

import {
  KONAKART_SERVICE_URL,
  ADDRESS_SERVICE_URL,
  JSON_CONFIG_URL,
  BACKEND_ENDPOINT,
  LIFERAY_CONNECT_ENDPOINT,
  NAVIGATION_URL,
} from '../config';
import { sentryService } from '../services';

const requestInterceptor = async (config: AxiosRequestConfig) => {
  const accessToken = await getClient()?.getTokenSilently();
  config.headers['authorization'] = config.headers['authorization'] ?? `Bearer ${accessToken}`;
  return config;
};
const responseInterceptor = async (err: any) => {
  if (err.response) {
    sentryService.captureException(err, { contexts: { response: err.response } });
    if (err.response.status === 401 || err.response.status === 403) {
      window.localStorage.setItem('redirectTo', window.location.pathname + window.location.search);
      getClient()?.logout({ returnTo: window.location.origin });
    }
  }
  return Promise.reject(err);
};

export const agentMyAccount = axios.create({
  baseURL: BACKEND_ENDPOINT,
});
export const kkAgent = axios.create({ baseURL: KONAKART_SERVICE_URL });
export const addressAgent = axios.create({ baseURL: ADDRESS_SERVICE_URL });
export const agentJSONConfig = axios.create({ baseURL: JSON_CONFIG_URL });
export const myAccountAgent = axios.create({ baseURL: BACKEND_ENDPOINT });
export const liferayAgent = axios.create({ baseURL: LIFERAY_CONNECT_ENDPOINT });
export const agentPrequal = axios.create({
  baseURL: BACKEND_ENDPOINT,
  validateStatus: function() {
    return true;
  },
});
export const agentNavigation = axios.create({
  baseURL: NAVIGATION_URL,
});

kkAgent.interceptors.request.use(headerChannelInterceptor('WEB-QUIZ'));
myAccountAgent.interceptors.request.use(headerChannelInterceptor('WEB-QUIZ'));

agentMyAccount.interceptors.request.use(requestInterceptor);
agentMyAccount.interceptors.response.use(resp => resp, responseInterceptor);
