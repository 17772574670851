import { Checked, Unchecked } from '@sky-tv-group/components';
import React, { useState } from 'react';
import cx from 'classnames';
import { useQuizJourneyState } from '../../../../provider/QuizJourney';
import { QuizItem } from '../../../../types';

interface Props {
  item: QuizItem;
  action: Function;
  disabled?: boolean;
}

const BasicCard = ({ item, action, disabled }: Props) => {
  const { selectedItems } = useQuizJourneyState();
  const [active, toggleActive] = useState(false);
  const classes = cx({
    'border-skyPurple border-2': active || selectedItems[item.id],
    border: !active && !selectedItems[item.id],
  });

  return (
    <button
      className={`w-full min-h-32 p-6 rounded-lg flex flex-col bg-white items-center justify-center relative cursor-pointer transition ease-in-out duration-200 border border-gray-lightBorder ${
        !disabled ? 'hover:border-skyPurple' : 'cursor-not-allowed'
      }  ${classes}`}
      onClick={() => action()}
      onMouseOver={() => toggleActive(true)}
      onMouseOut={() => toggleActive(false)}
      disabled={disabled}>
      <div className="absolute p-2 top-0 right-0">{selectedItems[item.id] ? <Checked /> : <Unchecked />}</div>
      {item.imageURL ? (
        <img
          style={{ maxHeight: '5rem' }}
          src={item.imageURL}
          alt={`${item.title} Image`}
          className="w-36 max-w-full object-contain object-center"
        />
      ) : (
        <strong className={`font-skyBold text-xl`}>{item.title}</strong>
      )}
    </button>
  );
};

export default BasicCard;
