import React from 'react';
import { useQuizJourneyState } from '../../../../provider/QuizJourney';
import { QuizItem, QuizRoute } from '../../../../types';
import IconCard from '../../cards/IconCard';

interface Props {
  step: QuizRoute;
}

const MaximumNumberOfPeopleStep = ({ step }: Props) => {
  const { updateScore, selectedItems, quizCategories } = useQuizJourneyState();

  if (!updateScore) return <p>Loading</p>;

  const _updateScore = (quizItem: QuizItem) => {
    const _otherRelatedItems = quizCategories.filter(c => c.step === step.step && c.id !== quizItem.id).map(c => c.id);

    // Since this is treated like a radio, we need to unselect all other related options for this step
    _otherRelatedItems.map((id: string) => {
      if (selectedItems[id]) updateScore(id);
    });

    updateScore(quizItem.id);
  };

  return (
    <div className="grid grid-cols-2 gap-4 md:gap-10 w-full md:w-175">
      {quizCategories
        .filter(c => c.step === step.step)
        .map(c => (
          <IconCard action={() => _updateScore(c)} label={c.title} icon={c.icon!} selected={selectedItems[c.id]} />
        ))}
    </div>
  );
};

export default MaximumNumberOfPeopleStep;
