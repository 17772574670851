import { CarouselContainer, closex } from '@sky-tv-group/components';
import { categoryIds, productSkuIds, T_Product } from '@sky-tv-group/shared';
import React from 'react';
import Slider from 'react-slick';
import { useQuizJourneyState } from '../../../../../../provider/QuizJourney';
import { SUGGESTED_BUNDLE } from '../../../../../../types';

interface Props {
  bundle: SUGGESTED_BUNDLE;
  title: string;
  activeProductSKU: string;
  setActiveProductSKU: (sku: string) => void;
}

const Carousel = ({ bundle, title, activeProductSKU, setActiveProductSKU }: Props) => {
  let slider = React.createRef<Slider>();

  const slickSettings = {
    autoSlidesToShow: true,
    variableWidth: true,
    infinite: false,
    arrows: false,
    slidesToShow: 4.2,
    dots: false,
    swipeToSlide: true,
    customClassName: 'custom-carousel bundle-carousel',
  };

  const handleOnClick = (index: number) => {
    const _slider = slider.current!;
    _slider.slickGoTo(index);
    setActiveProductSKU(
      bundle.order.orderProducts.filter(
        p =>
          /* Don't display products below. */
          p.product.sku !== productSkuIds.skyRouter.primary &&
          p.categoryId !== categoryIds.oneOffFee &&
          p.categoryId !== categoryIds.broadbandTechnicianFee &&
          p.categoryId !== categoryIds.monthlyFee &&
          p.categoryId !== categoryIds.broadbandServices &&
          p.categoryId !== categoryIds.broadbandOneOffFee &&
          p.categoryId !== categoryIds.broadbandDevices &&
          p.categoryId !== categoryIds.technicianFee
      )[index].product.sku
    );
  };

  return (
    <div className="relative w-full flex flex-col items-center justify-center bg-gray-lightest border-b border-gray-lightestBorder pt-24 pl-20">
      <h3 className="sky-h6-vlack md:sky-h3-black text-navy">{title}</h3>
      <p className="md:flex items-center sky-h7-reg text-lg text-navy mb-18">Click each item to explore more detail</p>

      {/* Carousel */}
      <div className="w-full">
        <CarouselContainer {...slickSettings} sliderRef={slider}>
          {bundle.order.orderProducts
            .filter(
              p =>
                /* Don't display products below. */
                p.product.sku !== productSkuIds.skyRouter.primary &&
                p.categoryId !== categoryIds.oneOffFee &&
                p.categoryId !== categoryIds.broadbandTechnicianFee &&
                p.categoryId !== categoryIds.monthlyFee &&
                p.categoryId !== categoryIds.broadbandServices &&
                p.categoryId !== categoryIds.broadbandOneOffFee &&
                p.categoryId !== categoryIds.broadbandDevices &&
                p.categoryId !== categoryIds.technicianFee
            )
            .map((p, index) => {
              return (
                <Slide
                  product={p.product}
                  active={p.product.sku === activeProductSKU}
                  onClick={() => handleOnClick(index)}
                />
              );
            })}
        </CarouselContainer>
      </div>
    </div>
  );
};

interface SlideProps {
  active: boolean;
  product: T_Product;
  onClick: Function;
}

const Slide = ({ active, product, onClick }: SlideProps) => {
  const { posters } = useQuizJourneyState();

  /* Use image from KK instead of some random splash image. */
  const poster = posters.find(p => p.id === product.sku)?.imageURL ?? product.custom1;

  return (
    <div className={`w-full h-full px-5 relative ${active ? 'z-20 border-b-2 border-blue-dark' : undefined}`}>
      <div className="flex flex-col pb-20 h-full" onClick={() => onClick()}>
        <img
          style={{ height: '80%' }}
          className={`w-full rounded-md transform scale-100 ${
            active ? 'transform scale-135' : 'border-transparent hover:border-skyPurple border-2'
          }`}
          src={poster}
          alt={product.name}
        />
        <p className={`text-lg font-skyBold mt-2 text-center ${active ? 'hidden' : 'visible'}`}>{product.name}</p>

        <p
          className={`text-lg font-skyBold mt-auto text-center absolute bottom-0 left-1/2 -translate-x-1/2 -translate-y-3 transform ${
            !active ? 'hidden' : 'visible'
          }`}>
          {product.name}
        </p>
      </div>
      {!active && (
        <button
          style={{ marginTop: '-4.5rem' }}
          className="text-3xl rounded-full w-6 h-6 text-white bg-gray-copy transform rotate-45 -translate-y-1/2 translate-x-3 absolute right-0 top-1/2">
          {closex}
        </button>
      )}
    </div>
  );
};

export default Carousel;
