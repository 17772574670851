/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useStore } from '../../../../../store/store';
import { useQuizJourneyState } from '../../../../../provider/QuizJourney';
import { BroadbandReady } from '@sky-tv-group/shared';

type FibreDescriptionProps = {
  clearInstallationAddressField: () => void;
};

const FibreDescription = ({ clearInstallationAddressField }: FibreDescriptionProps) => {
  const { fibreStatus } = useQuizJourneyState();
  const descriptionStart = 'Fibre is not due to be available in your street';
  const descriptionEnd = ", so we'll be in touch nearer the time so you can start enjoying Sky Broadband!";

  const { selectedTuiAddress } = useStore(s => ({
    selectedTuiAddress: s.selectedTuiAddress,
  }));

  if (fibreStatus === BroadbandReady.FibreOK) {
    return (
      <div className="text-sm py-3">
        <div className="text-bborange">
          Not your address?{' '}
          <a className="underline cursor-pointer" onClick={clearInstallationAddressField}>
            Check another address
          </a>
        </div>
      </div>
    );
  } else if (fibreStatus === BroadbandReady.FibreFuture) {
    return (
      <div className="text-sm py-3 text-bborange">
        {descriptionStart}
        {descriptionEnd}
      </div>
    );
  } else if (fibreStatus === BroadbandReady.Unknown || fibreStatus === BroadbandReady.FibreNever) {
    return (
      <div className="text-sm py-3 text-bborange">
        Thank you for your interest in new Sky Broadband, but unfortunately fibre is not available in your area. There
        are no current government plans to roll out fibre to your address.&nbsp;
        <a className="underline cursor-pointer" onClick={clearInstallationAddressField}>
          Check another address
        </a>
      </div>
    );
  } else if (selectedTuiAddress === '') {
    return <div className="text-sm py-3 text-bborange">See if fibre is available at your place</div>;
  } else {
    return <div></div>;
  }
};

export { FibreDescription };
