import { SplitFactory } from '@splitsoftware/splitio-react';
import React, { useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { Auth0Provider } from './auth0/auth0';
import { swrConfig, useOrderStore, useProductStore } from '@sky-tv-group/shared';
import config from './auth0/auth0.config';
import { SPLIT_AUTHORIZATION_KEY, SPLIT_KEY } from './config';
import history from './history';
import ControlPanel from './components/debugging/ControlPanel';
import { UIQuizJourneyProvider } from './provider/QuizJourney';
import { konakartService } from './services';
import QuestionaireWrapper from './components/quiz/QuestionaireWrapper';
import { RunningTotal } from './components/debugging/RunningTotal';
import { BlockLoader, Header, Loader, ToastContainer } from '@sky-tv-group/components';
import { useCustomerStore } from './store/customerStore';
import { useAuth0 } from './auth0/store';
import { clearSessionStorageState, useStore } from './store/store';
import { AppRoutes } from './constants';

const onRedirectCallback = (appState: any) => {
  // Prevent tracking the 'page view' event on the callBack as we track it already on App
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname, {
    noScroll: true,
    preventPageTrack: true,
  });
};

export const ProtectedApp = () => {
  const { loading } = useAuth0();

  if (loading) return <Loader bgOpacity={false} />;

  return <App />;
};

export const App = () => {
  const sdkConfig: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: SPLIT_AUTHORIZATION_KEY,
      key: SPLIT_KEY,
    },
  };

  const { isAuthenticated, loginWithRedirect, login, logout, navLinks, user } = useAuth0();
  const { clearPersistedButtonStates, isBundle } = useStore(s => ({
    clearPersistedButtonStates: s.clearPersistedButtonStates,
    isBundle: s.isBundle,
  }));
  const { customerId, getTempCustomerId, setExistingCustomerId } = useCustomerStore(s =>
    // Selecting parts of store to bind to root component
    ({
      customerId: s.kk,
      getTempCustomerId: s.getTempCustomerId,
      setActiveGASession: s.setActiveGASession,
      setExistingCustomerId: s.setExistingCustomerId,
    })
  );
  const { loadProductStore, initializedProductStore, updateFromOrderProducts } = useProductStore(s =>
    // Selecting parts of store to bind to root component
    ({
      loadProductStore: s.loadProductStore,
      initializedProductStore: s.initializedProductStore,
      updateFromOrderProducts: s.updateFromOrderProducts,
    })
  );
  const { initializedOrderStore, loadOrderStore, updateOrder } = useOrderStore(s =>
    // Selecting parts of store to bind to root component
    ({
      initializedOrderStore: s.initializedOrderStore,
      loadOrderStore: s.loadOrderStore,
      updateOrder: s.updateOrder,
    })
  );

  const logoutHandler = async () => {
    clearPersistedButtonStates();
    clearSessionStorageState();
    await konakartService.updateOrder(customerId, []);
    // clear id on logout so guests will use a different id
    setExistingCustomerId('');
    logout();
  };

  const loginCallbackUrls: string[] = [AppRoutes.Home, AppRoutes.Broadband].map(r => `${window.location.origin}${r}`);

  const loginHandler = () => {
    const allowLogin = loginCallbackUrls.includes(window.location.href);
    // only / and /broadband route is allowed for login callback redirects, everything else route to home.
    // this is because we don't allow existing customers from logging-in in acquisition urls
    // like '/checkout' and '/tv', it messes up their order
    //
    // also if a bundle plan is selected, we redirect and clear cart
    if (!allowLogin || isBundle) {
      login();
    } else {
      loginWithRedirect(window.location.href);
    }
  };

  // on mounted run the load mutation
  useEffect(() => {
    // if everything is loaded then we want to update the order to reflect - existing products in cart and coupons applied
    const updateOrderAtStart = async () => {
      try {
        const id = await getTempCustomerId(konakartService);
        await loadOrderStore(konakartService, id);
        await loadProductStore(konakartService);
      } catch (errors) {}
    };
    updateOrderAtStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTempCustomerId, loadOrderStore, loadProductStore, updateFromOrderProducts, updateOrder]);

  if (!(customerId && initializedProductStore && initializedOrderStore))
    return (
      <div className="h-128">
        <BlockLoader />
      </div>
    );

  return (
    <SplitFactory config={sdkConfig} updateOnSdkUpdate={true}>
      <SWRConfig value={swrConfig}>
        <Header
          isSignedIn={isAuthenticated}
          name={user?.given_name}
          SessionClickHandler={isAuthenticated ? logoutHandler : loginHandler}
          links={navLinks}
        />
        <Router history={history}>
          <Switch>
            <Route
              path="/"
              component={({ location }: any) => (
                <UIQuizJourneyProvider>
                  <div id="app-wrapper" className="flex flex-row bg-gray-background overflow-auto">
                    <QuestionaireWrapper />
                    {/* Allow testers to enter debug mode and play with scoring system */}
                    {location.search.indexOf('debug=true') !== -1 && <ControlPanel />}
                    {location.search.indexOf('total=true') !== -1 && (
                      <RunningTotal show={true} konakartService={konakartService} />
                    )}
                  </div>
                </UIQuizJourneyProvider>
              )}
            />
          </Switch>
        </Router>
      </SWRConfig>
    </SplitFactory>
  );
};

export function Root() {
  const sdkConfig: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: SPLIT_AUTHORIZATION_KEY,
      key: SPLIT_KEY,
    },
  };

  return (
    <SplitFactory config={sdkConfig} updateOnSdkUpdate={true}>
      <SWRConfig value={swrConfig}>
        <Router history={history}>
          <Auth0Provider
            config={{
              domain: config.domain,
              client_id: config.clientId,
              redirect_uri: window.location.origin,
              audience: config.audience,
              advancedOptions: {
                defaultScope: config.scope,
              },
            }}
            onRedirectCallback={onRedirectCallback}>
            <ProtectedApp />
          </Auth0Provider>
        </Router>
        <ToastContainer />
      </SWRConfig>
    </SplitFactory>
  );
}
