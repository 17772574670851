import create from 'zustand';
import { ReduxDevTools, KonakartService, T_Profile } from '@sky-tv-group/shared';
import { getCustomerId } from './helpers/getCustomerId';

// the store for managing products in the cart
interface CustomerStore {
  kk: string;
  sessionId: string;
  isActiveGASession: boolean;
  broadbandBackUrl: string;
  isCustomerQualifiedForBroadband: boolean;
  isAbandonedCartSession: boolean;
  isStaffAccount: boolean;
  hasPendingCableWorkOrder: boolean;
  getTempCustomerId: (kkService: KonakartService) => Promise<string>;
  getCustomerIdFromProfile: (profile: T_Profile) => string;
  setExistingCustomerId: (customerId: string) => void;
  setBroadbandBackUrl: (url: string) => void;
  setSessionId: (newSessionId: string) => void;
  setActiveGASession: (sessionActive: boolean) => void;
  setCustomerQualifiedForBroadband: (isQualified: boolean) => void;
  setIsAbandonedCartSession: (isAbandoned: boolean) => void;
  setIsStaffAccount: (isStaff: boolean) => void;
  setHasPendingCableWorkOrder: (hasPendingCableWorkOrder: boolean) => void;
}

/**
 * Responsible for holding the customerId that is tied to konakart. Adds to session storage.
 * IMPORTANT: Does not do any update to konakart. That should be done via order store.
 */
const [useCustomerStore, customerStoreApi] = create<CustomerStore>(
  ReduxDevTools(
    // @ts-ignore
    (set, get) => ({
      kk: '',
      bbSpaCartUrl: '',
      sessionId: '',
      isActiveGASession: true,
      isCustomerQualifiedForBroadband: false,
      isAbandonedCartSession: false,
      isStaffAccount: false,
      hasPendingCableWorkOrder: false,
      // @ts-ignore
      getTempCustomerId: async kkService => {
        let { kk } = get();
        if (!kk) {
          const customerId = (await kkService.getTempCustomerId()).r;
          set({ kk: customerId }, 'get temporary customer id');
          return customerId;
        } else {
          return kk;
        }
      },
      // @ts-ignore
      getCustomerIdFromProfile: profile => {
        return getCustomerId(profile);
      },
      // @ts-ignore
      setExistingCustomerId: newCustomerId => {
        set({ kk: newCustomerId }, 'set existing customer id');
      },
      // @ts-ignore
      setBroadbandBackUrl: url => {
        set({ broadbandBackUrl: url });
      },
      // @ts-ignore
      setSessionId: newSessionId => {
        set({ sessionId: newSessionId });
      },
      // @ts-ignore
      setActiveGASession: activeSession => {
        set({ isActiveGASession: activeSession });
      },
      // @ts-ignore
      setCustomerQualifiedForBroadband: isQualified => {
        set({ isCustomerQualifiedForBroadband: isQualified });
      },
      // @ts-ignore
      setIsAbandonedCartSession: isAbandoned => {
        set({ isAbandonedCartSession: isAbandoned });
      },
      // @ts-ignore
      setIsStaffAccount: isStaff => {
        set({ isStaffAccount: isStaff });
      },
      // @ts-ignore
      setHasPendingCableWorkOrder: hasPendingCableWorkOrder => {
        set({ hasPendingCableWorkOrder: hasPendingCableWorkOrder });
      },
    }),
    'Customer Store'
  )
);

export { useCustomerStore, customerStoreApi };
