import { T_Coupon, T_CURATED_BUNDLE, T_Order } from '@sky-tv-group/shared';

export enum QuizStep {
  INTEREST,
  INSTALLATION_ADDRESS,
  TV,
  BOX_OPTIONS,
  APPS,
  BUNDLE,
  WIFI_USE,
  MAXIMUM_NUMBER_OF_PEOPLE,
  WIFI_BOOST,
  RECOMMENDATIONS,
}

export enum JourneyType {
  TV_ONLY,
  BOX,
  BROADBAND_ONLY,
}

export enum StepTransitionType {
  NONE,
  LEAVE,
  ENTER,
}

export type QuizItem = {
  id: string;
  title: string;
  caption?: string;
  tags?: string[];
  step: QuizStep;
  video?: string;
  imageURL?: string;
  icon?: () => JSX.Element;
  weightings: { [x: string]: number };
  secondaryWeightings?: { [x: string]: number };
};

export type QuizRoute = {
  slug: string;
  title: string;
  caption?: string;
  step: QuizStep;
  mandatory?: boolean;
  component: ({ step }: { step: QuizRoute }) => JSX.Element;
  condition?: {
    type: 'SELECTED' | 'NOT_SELECTED';
    answers?: QuizItem[];
    customAnswers?: 'FIBRE_ELIGIBLE'[];
    specificToJourney?: JourneyType[];
  }[];
};

export type Journey = {
  journeyType: JourneyType;
  routes: QuizRoute[];
};

export type YesNoValueType = 'YES' | 'NO';

export type SkyCustomerYesNoType = {
  YES: 'YES';
  NO: 'NO';
};

export const SkyCustomerYesNoOption: SkyCustomerYesNoType = {
  YES: 'YES',
  NO: 'NO',
};

export type BundleType = 'BEST_VALUE' | 'RECOMMENDED' | 'UP_SELL';

export interface RECOMMENDED_BUNDLE_STRUCTURE {
  customerId: string;
  skus: string[];
  price: string;
}

export interface RECOMMENDED_BUNDLES {
  best_value: RECOMMENDED_BUNDLE_STRUCTURE;
  recommended: RECOMMENDED_BUNDLE_STRUCTURE;
  upsell: RECOMMENDED_BUNDLE_STRUCTURE;
}

export interface BundleThresholdGroup {
  tvPackagesThreshold: number;
  channelThreshold: number;
  boosterThreshold: number;
}

export interface BundleThresholdWeights {
  best_value: BundleThresholdGroup;
  recommended: BundleThresholdGroup;
  upsell: BundleThresholdGroup;
}

export interface SUGGESTED_BUNDLE {
  order: T_Order;
  coupons: T_Coupon[];
}

export interface T_SpeedShowCase {
  name: string;
  backgroundImg: string;
  mobileBackgroundImg: string;
  download: {
    title: string;
    desc: string;
    speed: string;
  };
  upload: {
    title: string;
    desc: string;
    speed: string;
  };
}
