import React from 'react';

const TV = ({ active }: { active: boolean }) => {
  const fill = active ? 'url(#paint0_linear_374_71760)' : 'black';

  return (
    <svg width="100%" height="100%" viewBox="0 0 87 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5005 6.19507C5.5005 5.60544 5.97849 5.12746 6.56812 5.12746H80.29C80.8797 5.12746 81.3576 5.60544 81.3576 6.19507V52.9455C81.3576 53.5352 80.8797 54.0132 80.29 54.0132H73.1385C73.1125 54.0127 73.0863 54.0127 73.0602 54.013C73.057 54.0131 73.0538 54.0131 73.0506 54.0132H13.8062L13.8039 54.0131C13.7753 54.0127 13.7468 54.0127 13.7183 54.0132H6.56812C5.97849 54.0132 5.5005 53.5352 5.5005 52.9455V6.19507ZM71.6653 59.0703H15.1915L7.83665 71.3284C7.11816 72.5259 5.56496 72.9142 4.36748 72.1957C3.16999 71.4772 2.78169 69.924 3.50018 68.7265L9.29391 59.0703H6.56812C3.18551 59.0703 0.443359 56.3282 0.443359 52.9455V6.19507C0.443359 2.81246 3.18551 0.0703125 6.56812 0.0703125H80.29C83.6726 0.0703125 86.4148 2.81246 86.4148 6.19507V52.9455C86.4148 56.3282 83.6726 59.0703 80.29 59.0703H77.5629L83.3566 68.7265C84.0751 69.924 83.6868 71.4772 82.4893 72.1957C81.2919 72.9142 79.7387 72.5259 79.0202 71.3284L71.6653 59.0703Z"
        fill={fill}
      />
      <defs>
        <linearGradient
          id="paint0_linear_374_71760"
          x1="42.3044"
          y1="74.2841"
          x2="42.3044"
          y2="3.84727"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#F94CB1" />
          <stop offset="0.04" stop-color="#EF45B4" />
          <stop offset="0.48" stop-color="#9100D4" />
          <stop offset="1" stop-color="#344AA2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TV;
