import { Button } from '@sky-tv-group/components';
import { useProductStore } from '@sky-tv-group/shared';
import React from 'react';
import { useState } from 'react';
import { useQuizJourneyState } from '../../../provider/QuizJourney';
import { BundleThresholdGroup, BundleThresholdWeights } from '../../../types';

enum TabType {
  SCORES,
  SECONDARY_SCORES,
  BUNDLES,
}

const ControlPanel = () => {
  const {
    score,
    secondaryScore,
    copyWeightingSystemToClipBoard,
    quizCategories,
    updateWeightingSystem,
    bundleThresholds,
    updateBundleThresholds,
    secondaryBundleThresholds,
    updateSecondaryBundleThresholds,
    updateSecondaryWeightingSystem,
  } = useQuizJourneyState();
  const [tab, setTab] = useState<TabType>(TabType.SCORES);
  const { products } = useProductStore();

  return (
    <div
      style={{ top: '60px', height: 'calc(100vh - 14rem)' }}
      className="fixed right-0 w-110 bg-white border-l border-gray-lightBorder overflow-y-auto pb-8">
      <h4 className=" text-lg font-skyBlack mb-2 ml-6 mt-4">Control panel</h4>
      <ul className="flex flex-row mt-4 border-b">
        <button
          className={`px-4 ${tab === TabType.SCORES ? 'border-b-2 border-blue-dark' : undefined}`}
          onClick={() => setTab(TabType.SCORES)}>
          Primary Scores
        </button>
        <button
          className={`px-4 ${tab === TabType.SECONDARY_SCORES ? 'border-b-2 border-blue-dark' : undefined}`}
          onClick={() => setTab(TabType.SECONDARY_SCORES)}>
          Secondary Scores
        </button>
        <button
          className={`px-4 ${tab === TabType.BUNDLES ? 'border-b-2 border-blue-dark' : undefined}`}
          onClick={() => setTab(TabType.BUNDLES)}>
          Bundles
        </button>
      </ul>

      {tab === TabType.SCORES && (
        <div>
          {score !== null && (
            <div className="p-6">
              <h4 className=" text-lg mb-2">Primary Scores</h4>
              <ul>
                {Object.keys(score)
                  .sort((a, b) => score[b] - score[a])
                  .map(sku => {
                    const product = products.find(p => p.sku === sku);
                    return (
                      <li>
                        {product?.name ?? product?.sku}: {score[sku]}
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}

          {quizCategories.length && (
            <div className="p-6">
              <h4 className=" text-lg mb-2">Categories</h4>

              <ul>
                {quizCategories.map(c => (
                  <li className="mb-2">
                    <div className="w-full">{c.title}</div>

                    {updateWeightingSystem && (
                      <div className="border-l-2 pl-2 mt-1">
                        {Object.keys(c.weightings).map(sku => {
                          const product = products.find(p => p.sku === sku);

                          return (
                            <div className="flex flex-row">
                              <p className="text-xs">
                                {product?.name ?? product?.sku}
                                <span className="text-sm">({c.weightings[sku]})</span>
                              </p>
                              <div className="relative ml-auto">
                                <input
                                  type="range"
                                  min={0}
                                  max={50}
                                  defaultValue={c.weightings[sku]}
                                  onChange={e => updateWeightingSystem(sku, c.id, +e.target.value)}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {copyWeightingSystemToClipBoard && (
            <Button
              variant="primary"
              colorMode="pure"
              className={`fixed bottom-0 right-0 w-110 inline-block bg-blue-light rounded-none m-0`}
              style={{ bottom: '10.15rem' }}
              onClick={copyWeightingSystemToClipBoard}>
              Copy Weighting
            </Button>
          )}
        </div>
      )}

      {tab === TabType.SECONDARY_SCORES && (
        <div>
          {secondaryScore !== null && (
            <div className="p-6">
              <h4 className=" text-lg mb-2">Secondary Scores</h4>
              <ul>
                {Object.keys(secondaryScore)
                  .sort((a, b) => secondaryScore[b] - secondaryScore[a])
                  .map(sku => {
                    const product = products.find(p => p.sku === sku);
                    return (
                      <li>
                        {product?.name ?? product?.sku}: {secondaryScore[sku]}
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}

          {quizCategories.length && (
            <div className="p-6">
              <h4 className=" text-lg mb-2">Categories</h4>

              <ul>
                {quizCategories.map(c => (
                  <li className="mb-2">
                    <div className="w-full">{c.title}</div>

                    {updateSecondaryWeightingSystem && (
                      <div className="border-l-2 pl-2 mt-1">
                        {Object.keys(c.secondaryWeightings ?? {}).map(sku => {
                          const product = products.find(p => p.sku === sku);

                          if (!c.secondaryWeightings) return;

                          return (
                            <div className="flex flex-row">
                              <p className="text-xs">
                                {product?.name ?? product?.sku}
                                <span className="text-sm">({c.secondaryWeightings[sku]})</span>
                              </p>
                              <div className="relative ml-auto">
                                <input
                                  type="range"
                                  min={0}
                                  max={50}
                                  defaultValue={c.secondaryWeightings[sku]}
                                  onChange={e => updateSecondaryWeightingSystem(sku, c.id, +e.target.value)}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {copyWeightingSystemToClipBoard && (
            <Button
              variant="primary"
              colorMode="pure"
              className={`fixed bottom-0 right-0 w-110 inline-block bg-blue-light rounded-none m-0`}
              style={{ bottom: '10.15rem' }}
              onClick={copyWeightingSystemToClipBoard}>
              Copy Weighting
            </Button>
          )}
        </div>
      )}

      {tab === TabType.BUNDLES && (
        <div>
          {bundleThresholds !== null && (
            <div className="px-6 py-4">
              <h4 className=" text-lg mb-2">Primary Bundles</h4>
              <ul>
                {Object.keys(bundleThresholds).map((k: string) => {
                  const key = k as keyof BundleThresholdWeights;
                  const item: BundleThresholdGroup = bundleThresholds[key as keyof BundleThresholdWeights];

                  return (
                    <li className="mb-2">
                      <div className="w-full capitalize">{key.replace('_', ' ')}</div>

                      <div className="border-l-2 pl-2 mt-1">
                        <div className="flex flex-row">
                          <p className="text-xs">
                            TV
                            <span className="text-sm">({item.tvPackagesThreshold})</span>
                          </p>
                          <div className="relative ml-auto">
                            <input
                              type="range"
                              min={0}
                              max={50}
                              defaultValue={item.tvPackagesThreshold}
                              onChange={e => updateBundleThresholds(key, +e.target.value, 'TV')}
                            />
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <p className="text-xs">
                            Premium Channel
                            <span className="text-sm">({item.channelThreshold})</span>
                          </p>
                          <div className="relative ml-auto">
                            <input
                              type="range"
                              min={0}
                              max={50}
                              defaultValue={item.channelThreshold}
                              onChange={e => updateBundleThresholds(key, +e.target.value, 'CHANNEL')}
                            />
                          </div>
                        </div>

                        <div className="flex flex-row">
                          <p className="text-xs">
                            Booster
                            <span className="text-sm">({item.boosterThreshold})</span>
                          </p>
                          <div className="relative ml-auto">
                            <input
                              type="range"
                              min={0}
                              max={50}
                              defaultValue={item.boosterThreshold}
                              onChange={e => updateBundleThresholds(key, +e.target.value, 'BOOSTER')}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          {secondaryBundleThresholds !== null && (
            <div className="px-6 py-4">
              <h4 className=" text-lg mb-2">Secondary Bundles</h4>
              <p className=" text-xs mb-2">These are used conditionally to prevent identical bundles</p>
              <ul>
                {Object.keys(secondaryBundleThresholds).map((k: string) => {
                  const key = k as keyof BundleThresholdWeights;
                  const item: BundleThresholdGroup = secondaryBundleThresholds[key as keyof BundleThresholdWeights];

                  return (
                    <li className="mb-2">
                      <div className="w-full capitalize">{key.replace('_', ' ')}</div>

                      <div className="border-l-2 pl-2 mt-1">
                        <div className="flex flex-row">
                          <p className="text-xs">
                            TV
                            <span className="text-sm">({item.tvPackagesThreshold})</span>
                          </p>
                          <div className="relative ml-auto">
                            <input
                              type="range"
                              min={0}
                              max={50}
                              defaultValue={item.tvPackagesThreshold}
                              onChange={e => updateSecondaryBundleThresholds(key, +e.target.value, 'TV')}
                            />
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <p className="text-xs">
                            Premium Channel
                            <span className="text-sm">({item.channelThreshold})</span>
                          </p>
                          <div className="relative ml-auto">
                            <input
                              type="range"
                              min={0}
                              max={50}
                              defaultValue={item.channelThreshold}
                              onChange={e => updateSecondaryBundleThresholds(key, +e.target.value, 'CHANNEL')}
                            />
                          </div>
                        </div>

                        <div className="flex flex-row">
                          <p className="text-xs">
                            Booster
                            <span className="text-sm">({item.boosterThreshold})</span>
                          </p>
                          <div className="relative ml-auto">
                            <input
                              type="range"
                              min={0}
                              max={50}
                              defaultValue={item.boosterThreshold}
                              onChange={e => updateSecondaryBundleThresholds(key, +e.target.value, 'BOOSTER')}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ControlPanel;
