import React from 'react';
import { useQuizJourneyState } from '../../../../provider/QuizJourney';
import { QuizRoute, QuizStep } from '../../../../types';
import IconCard from '../../cards/IconCard';
import TvCard from '../../cards/TvCard';
import Recording from './svgs/Recording';

interface Props {
  step: QuizRoute;
}

const LikeToWatchStep = ({ step }: Props) => {
  const { quizCategories, updateScore, selectedItems } = useQuizJourneyState();

  if (!updateScore) return <p>Loading</p>;

  return (
    <div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-6 w-full">
        {quizCategories
          .filter(c => c.step === QuizStep.BOX_OPTIONS)
          .map(c => (
            <div className="w-full">
              <IconCard
                icon={Recording}
                label={c.title}
                caption={c.caption}
                action={() => updateScore(c.id)}
                selected={selectedItems[c.id]}
                height={'h-full'}
              />
            </div>
          ))}
        {quizCategories
          .filter(c => c.step === step.step)
          .map(c => (
            <TvCard action={() => updateScore(c.id)} item={c} />
          ))}
      </div>

      {/* Leaving this here incase Bart finds a new solution based on current design */}
      {/* <div className="mt-20">
        <h3 className="mb-20 sky-hy7-bold md:sky-h4-bold text-navy w-full text-center">
          Would you like to be able to pause, rewind and record live TV?
        </h3>

        <div className="flex items-center justify-center">
          {categories
            .filter(c => c.step === QuizStep.BOX_OPTIONS)
            .map(c => (
              <div className="w-full md:max-w-xs">
                <IconCard
                  icon={Recording}
                  label={c.title}
                  action={() => updateScore(c.id)}
                  selected={selectedItems[c.id]}
                />
              </div>
            ))}
        </div>
      </div> */}
    </div>
  );
};

export default LikeToWatchStep;
