import React from 'react';

const Recording = ({ active }: { active: boolean }) => {
  const fill = active ? 'url(#clip0_7791_48239)' : 'black';

  return (
    <svg viewBox="0 0 58 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7791_48239)">
        <path
          d="M56.6771 16.4906C54.0765 12.0069 51.6056 7.44551 48.8286 3.06737C47.6165 1.15841 45.7923 0.0362114 43.4943 0.0275658C33.8336 -0.00874581 24.1748 -0.00701668 14.5142 0.0275658C12.2213 0.0362114 10.3573 1.11691 9.15561 3.0276C6.35961 7.46799 3.81953 12.064 1.26735 16.6496C0.394138 18.2162 -0.0485176 19.89 0.00508522 21.716C0.072521 24.0209 0.0206473 26.3293 0.0223764 28.6359C0.0241056 33.6798 3.60858 37.266 8.64724 37.2677C15.4392 37.2677 22.2312 37.2677 29.0232 37.2677C35.8152 37.2677 42.6937 37.2729 49.5289 37.2677C54.348 37.2625 57.9757 33.6244 57.986 28.795C57.9912 26.4226 57.9532 24.0503 57.9981 21.6797C58.0345 19.8157 57.5987 18.0796 56.6771 16.4906ZM11.5453 7.04608C11.8461 6.52389 12.1435 5.99996 12.453 5.48468C12.9908 4.58727 13.7309 4.13078 14.8375 4.13424C22.0825 4.16363 29.3276 4.1498 36.5726 4.1498C38.7288 4.1498 40.8867 4.19648 43.0412 4.13078C44.2049 4.09447 44.9675 4.57516 45.5243 5.52791C46.3611 6.95789 47.2084 8.38096 48.0453 9.80922C48.5329 10.6427 49.0102 11.4813 49.5375 12.3994H8.45877C9.51007 10.5752 10.5268 8.81152 11.5453 7.04608ZM53.8413 28.7881C53.8344 31.4025 52.1174 33.1265 49.5099 33.1282C42.6747 33.1334 35.8394 33.1299 29.0042 33.1299C22.169 33.1299 15.4634 33.1299 8.6922 33.1299C5.80975 33.1299 4.15844 31.4821 4.15671 28.6083C4.15671 26.0422 4.15152 23.4762 4.15671 20.9119C4.1619 18.294 5.87373 16.5736 8.4847 16.5736C22.1552 16.5701 35.8256 16.5701 49.496 16.5736C52.1122 16.5736 53.8327 18.2906 53.8396 20.8998C53.8465 23.5298 53.8465 26.1598 53.8396 28.7915L53.8413 28.7881Z"
          fill="black"
        />
        <path
          d="M35.1755 22.7828C31.7277 22.7742 28.2798 22.7794 24.8337 22.7794C21.4083 22.7794 17.9811 22.7742 14.5557 22.7828C13.2624 22.7863 12.4307 23.6249 12.4428 24.8751C12.4549 26.0924 13.2779 26.9154 14.5419 26.9172C21.4152 26.9241 28.2885 26.9206 35.1617 26.9224C35.6061 26.9224 36.0107 26.8065 36.3756 26.5523C37.1225 26.0336 37.4493 25.1293 37.1969 24.2837C36.922 23.3621 36.1733 22.788 35.1755 22.7846V22.7828Z"
          fill="black"
        />
        <path
          d="M43.5428 22.7812C42.3894 22.757 41.435 23.6838 41.4263 24.832C41.4194 25.9576 42.3237 26.8931 43.4459 26.9173C44.5975 26.9432 45.5537 26.0147 45.5641 24.8665C45.5727 23.7461 44.6598 22.8054 43.5428 22.7812Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_7791_48239">
          <rect width="58" height="37.2713" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Recording;
