import React from 'react';

const TVAndBroadband = ({ active }: { active: boolean }) => {
  const stroke = active ? 'url(#paint0_linear_374_71760)' : 'black';

  return (
    <svg width="100%" height="100%" viewBox="0 0 86 87" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient
          id="paint0_linear_374_71760"
          x1="42.3044"
          y1="74.2841"
          x2="42.3044"
          y2="3.84727"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#F94CB1" />
          <stop offset="0.04" stop-color="#EF45B4" />
          <stop offset="0.48" stop-color="#9100D4" />
          <stop offset="1" stop-color="#344AA2" />
        </linearGradient>
      </defs>
      <path
        d="M74.8926 2.85938H49.7175C47.7313 2.85938 46.1211 4.46955 46.1211 6.45581V20.8416C46.1211 22.8278 47.7313 24.438 49.7175 24.438H74.8926C76.8788 24.438 78.489 22.8278 78.489 20.8416V6.45581C78.489 4.46955 76.8788 2.85938 74.8926 2.85938Z"
        stroke={stroke}
        stroke-width="5.05756"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.6324 23.1836L83.0774 33.6133C83.3513 34.1614 83.4806 34.7705 83.453 35.3827C83.4255 35.9949 83.2419 36.5898 82.9198 37.1112C82.5977 37.6325 82.1478 38.0629 81.6126 38.3615C81.0775 38.6601 80.4749 38.817 79.8621 38.8173H44.7466C44.1334 38.8176 43.5304 38.6611 42.9948 38.3628C42.4592 38.0644 42.0087 37.6341 41.6862 37.1127C41.3637 36.5913 41.1799 35.9961 41.1521 35.3836C41.1244 34.7711 41.2537 34.1617 41.5277 33.6133L46.9835 23.1836"
        stroke={stroke}
        stroke-width="5.05756"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.4895 50.5842V71.199C56.4895 72.1528 56.1106 73.0676 55.4361 73.742C54.7617 74.4165 53.8469 74.7954 52.8931 74.7954H6.1394C5.18557 74.7954 4.2708 74.4165 3.59634 73.742C2.92188 73.0676 2.54297 72.1528 2.54297 71.199V43.323C2.54297 42.3692 2.92188 41.4544 3.59634 40.7799C4.2708 40.1055 5.18557 39.7266 6.1394 39.7266H30.2355"
        stroke={stroke}
        stroke-width="5.05756"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5268 74.7969L16.0312 83.788"
        stroke={stroke}
        stroke-width="5.05756"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.5078 74.7969L43.0034 83.788"
        stroke={stroke}
        stroke-width="5.05756"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.28906 24.0047C11.3684 21.9931 14.9669 20.9219 18.645 20.9219C22.3231 20.9219 25.9216 21.9931 29.0009 24.0047"
        stroke={stroke}
        stroke-width="5.05756"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.7526 14.7285C30.0196 11.4561 24.402 9.70313 18.6478 9.70312C12.8936 9.70313 7.276 11.4561 2.54297 14.7285"
        stroke={stroke}
        stroke-width="5.05756"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TVAndBroadband;
