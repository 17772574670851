import { Journey, JourneyType, QuizStep } from '../types';
import { routes } from './routes';

export const journey: Journey[] = [
  {
    journeyType: JourneyType.TV_ONLY,
    routes: [
      routes.find(r => r.step === QuizStep.TV)!,
      routes.find(r => r.step === QuizStep.APPS)!,
      routes.find(r => r.step === QuizStep.RECOMMENDATIONS)!,
    ],
  },
  {
    journeyType: JourneyType.BROADBAND_ONLY,
    routes: [
      routes.find(r => r.step === QuizStep.INSTALLATION_ADDRESS)!,
      routes.find(r => r.step === QuizStep.WIFI_USE)!,
      routes.find(r => r.step === QuizStep.MAXIMUM_NUMBER_OF_PEOPLE)!,
      routes.find(r => r.step === QuizStep.WIFI_BOOST)!,
      routes.find(r => r.step === QuizStep.RECOMMENDATIONS)!,
    ],
  },
  {
    journeyType: JourneyType.BOX,
    routes: [
      routes.find(r => r.step === QuizStep.TV)!,
      routes.find(r => r.step === QuizStep.APPS)!,
      routes.find(r => r.step === QuizStep.INSTALLATION_ADDRESS)!,
      routes.find(r => r.step === QuizStep.WIFI_USE)!,
      routes.find(r => r.step === QuizStep.MAXIMUM_NUMBER_OF_PEOPLE)!,
      routes.find(r => r.step === QuizStep.WIFI_BOOST)!,
      routes.find(r => r.step === QuizStep.RECOMMENDATIONS)!,
    ],
  },
];
