import { closex, FancyPrice } from '@sky-tv-group/components';
import React, { useState } from 'react';

interface Props {
  subscribed: boolean;
  price: number;
  hide: () => void;
}

const Banner = ({ subscribed, price, hide }: Props) => {
  let labelInCart = subscribed ? 'Subscribed' : 'Remove';
  //   const handleRemove = useHandleRemove(product, kkService);
  const handleRemove = () => alert('REMOVE');

  const [isDisabled, setIsDisabled] = useState(false);

  const handleToggle = async () => {
    setIsDisabled(true);
    await handleRemove();
    setIsDisabled(false);
  };

  return (
    <div className=" h-20 bg-white shadow-md sticky z-50 top-0">
      <div className="container m-auto flex justify-between items-center h-full px-12 md:px-0">
        <button className="text-3xl rounded-full md:w-11 md:h-11 w-8 h-8 text-white bg-blue-pure" onClick={hide}>
          {closex}
        </button>
        <div className="flex flex-row items-center ">
          <FancyPrice
            price={price}
            numberStyles="sm:sky-h1-black sky-h1 font-bold w-full"
            containerClasses="text-navy relative "
          />
          <div className="w-8"></div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
