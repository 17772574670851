export enum AppRoutes {
  Home = '/',
  Broadband = '/broadband',
  TV = '/tv',
  Checking = '/checking',
  OrderSummary = '/order-summary',
  Checkout = '/checkout',
  Confirmation = '/confirmation',
  NotFound = '/notfound',
  NetworkStatus = '/network-status',
}

export const QuizTitles = {
  INTEREST: 'What Are You Interested In?',
  WATCH: 'What Do You like to Watch?',
  APPS: 'Which Apps do you currently use?',
  RECOMMENDATIONS: 'Here are our recommended packages for you',
  INSTALLATION_ADDRESS: 'What’s your installation address?',
  WIFI_USE: 'What is WiFi being used for in your home?',
  MAXIMUM_NUMBER_OF_PEOPLE: "What's the maximum number of people in your household using the internet at any one time?",
  WIFI_BOOST: 'Does you WiFi need a boost?',
};

export const QuizInteractionDetails = {
  CONTINUE: 'Continue',
  BACK: 'Back',
  EXPLORE: 'Details',
  GET_STARTED: 'Buy Now',
  WITHOUT_BB: 'Continue with TV Only',
};
