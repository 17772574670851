import React from 'react';
import { useQuizJourneyState } from '../../../../provider/QuizJourney';
import { QuizItem } from '../../../../types';

interface Props {
  item: QuizItem;
  action: Function;
}

const SwitchCard = ({ item, action }: Props) => {
  const { selectedItems } = useQuizJourneyState();
  return (
    <div
      className={`w-full p-6 rounded-lg flex flex-row bg-white items-center justify-between relative transition-all ease-in-out  hover:border-skyPurple`}>
      <strong className={`font-skyBook text-xl`}>{item.title}</strong>
      <TwoOptionsSelector selected={selectedItems[item.id]} action={action} />
    </div>
  );
};

interface TwoOptionsSelectorProps {
  selected: boolean;
  action: Function;
}
const TwoOptionsSelector = ({ selected, action }: TwoOptionsSelectorProps) => {
  let selectedClass = 'bg-skyGreen sky-text-white';
  let unselectedClass = 'sky-bg-gray-light text-gray-copy hover:bg-gray-dark';
  let buttonClass = 'p-4 w-1/2 sky-h7-reg font-skyBold';

  const _action = (chosenValue: boolean) => {
    if (chosenValue === selected) return;

    action(chosenValue);
  };

  return (
    <div className="flex flex-row w-44">
      <button
        onClick={() => _action(true)}
        className={`${buttonClass} rounded-l-full transition ease-in-out duration-300 ${
          selected ? selectedClass : unselectedClass
        }`}>
        Yes
      </button>
      <button
        onClick={() => _action(false)}
        className={`${buttonClass} rounded-r-full transition ease-in-out duration-300 ${
          !selected ? selectedClass : unselectedClass
        }`}>
        No
      </button>
    </div>
  );
};

export { TwoOptionsSelector };

export default SwitchCard;
