import { useJSONConfig } from '@sky-tv-group/shared';
import React from 'react';
import { configService } from '../../../../../../services';

let LatestTech = () => {
  let config = useJSONConfig(configService);

  let data: { title: string; desc: string; label: string; link: string; video: string } =
    config?.broadband?.acquisition?.latestTech;
  if (!data) return null;

  return (
    <div className="sky-header-gradient">
      <div className="md:container m-auto flex flex-wrap ">
        <div className="w-full md:w-1/3">
          <div className="p-12 text-white flex flex-col items-center md:items-start">
            <p className=" sky-h5-bold md:sky-h4-bold py-4">{data.title}</p>
            <p className=" sky-h6 ">{data.desc}</p>
            <a href={data.link} className="text-blue-light underline">
              {data.label}
            </a>
          </div>
        </div>
        <div className="w-full md:w-2/3">
          <div className="pt-0 md:pt-12 p-12 flex items-center h-full">
            <div className="relative block " style={{ width: '100%', maxWidth: 960 }}>
              <div style={{ paddingTop: '56.25%' }}>
                <iframe
                  title="video"
                  src={data.video}
                  allow="encrypted-media"
                  className="absolute top-0 left-0 right-0 bottom-0 w-full h-full"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export { LatestTech };
