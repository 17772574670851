import React from 'react';
import { useQuizJourneyState } from '../../../provider/QuizJourney';
import { QuizRoute, QuizStep, JourneyType } from '../../../types';
import Back from './components/svgs/Back';
import { BroadbandTerms } from '@sky-tv-group/components';
import { BroadbandReady } from '@sky-tv-group/shared';
import { ACCOUNT_URL } from '../../../config';
interface Props {
  step: QuizRoute;
}

const StepWrapper = ({ step }: Props) => {
  const { updateScore, resetQuiz, journeyType, fibreStatus } = useQuizJourneyState();
  const StepContent = step.component;

  if (!updateScore || !resetQuiz) return <p>Loading</p>;

  return (
    <div className="relative flex-1">
      <div
        style={{ minHeight: step.step === QuizStep.INTEREST ? `100vh` : `calc(100vh - 12rem)` }}
        className={`flex flex-col items-center flex-1 w-ful px-6 ${
          step.step !== (QuizStep.INTEREST || QuizStep.RECOMMENDATIONS) ? 'mb-48' : ''
        }${step.step === QuizStep.RECOMMENDATIONS ? 'mb-40' : ''} `}>
        <div className="my-10 flex flex-col items-center justify-center max-w-4xl text-center">
          <h3 className="sky-h6-bold md:sky-h3-bold text-navy">{step.title}</h3>
          {step.caption && (
            <p className="md:flex relative pt-4 pb-8 items-center sky-h7-reg text-base text-navy">{step.caption}</p>
          )}
          {step.step === QuizStep.INTEREST && (
            <div className="mt-6 text-center flex flex-col items-center">
              <p className="md:flex relative items-center sky-h7-reg text-base text-navy">
                <strong className="font-skyBold">New to Sky?</strong>&nbsp;Let us help you find the package that’s right
                for you.
              </p>
              <p className="md:flex relative pt-1 items-center sky-h7-reg text-base text-navy">or</p>
              <p className="md:flex relative pt-1 items-center sky-h7-reg text-base text-navy">
                login to&nbsp;
                <a className="underline text-blue-pure" href={ACCOUNT_URL} target="_blank">
                  My Account
                </a>
                &nbsp;for the best offers available to you if you're already a Sky Customer
              </p>
            </div>
          )}
          {step.step === QuizStep.RECOMMENDATIONS && (
            <button className="flex flex-row items-center pt-6 pb-8 sky-h6-reg text-navy" onClick={resetQuiz}>
              <Back />
              <span className="ml-2">Retake the Quiz</span>
            </button>
          )}
        </div>
        <div
          className={`w-full flex flex-col items-center justify-center ${
            step.step === QuizStep.RECOMMENDATIONS ? 'lg:max-w-6xl' : 'lg:max-w-5xl'
          }`}>
          <StepContent step={step} />
        </div>
        {step.step === QuizStep.RECOMMENDATIONS &&
          journeyType !== JourneyType.TV_ONLY &&
          fibreStatus !== BroadbandReady.FibreNever && (
            <BroadbandTerms
              isActiveSkyStarter={false}
              containerClassName="terms-container-style"
              fontClass="terms-font-style"
            />
          )}
      </div>
    </div>
  );
};

export default StepWrapper;
