import {
  Button,
  CheckCircle,
  DartDishIconStrikeThrough,
  FancyPrice,
  MarketingRectangle,
  NoRecordingIcon,
  RunningTotalOffer,
  RecordingIcon,
  DartDishIcon,
} from '@sky-tv-group/components';
import {
  categoryIds,
  productSkuIds,
  redirectToSubdomain,
  useLockBodyScrollOnValue,
  useToastContainer,
  useAnalytics,
  QuizEvents,
  QuizInteractionTypes,
  couponCodes,
  BroadbandReady,
  useSplitIO,
  SPLITIO_KEY,
} from '@sky-tv-group/shared';
import React, { useEffect, useRef, useState } from 'react';
import { useAuth0 } from '../../../../../../auth0/store';
import { QuizInteractionDetails, QuizTitles } from '../../../../../../constants';
import { useQuizJourneyState } from '../../../../../../provider/QuizJourney';
import { BundleType, JourneyType, SUGGESTED_BUNDLE } from '../../../../../../types';
import { getRecommendedProducts } from '../../../../../../utils';
import BundleExploreModal from '../BundleExploreModal';
import { useModal } from '../BundleExploreModal/useModal';
import { storeApi, set } from '../../../../../../store/store';

interface Props {
  type: BundleType;
  bundle: SUGGESTED_BUNDLE;
  containerClasses: string;
  containerStyle?: string;
}

const BundleCard = ({ type, bundle, containerClasses, containerStyle }: Props) => {
  const { showModal, toggleModal } = useModal();
  const [shouldHideExplore] = useSplitIO(SPLITIO_KEY.SKY_QUIZ_HIDE_EXPLORE);
  const { address, journeyType, fibreStatus } = useQuizJourneyState();
  useLockBodyScrollOnValue(showModal);
  const { user } = useAuth0();
  const { addToast } = useToastContainer();
  const { trackQuiz, productAdded } = useAnalytics();
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    /*To make all cards size dynamic wrt upsellcard in mobile */
    if (window.innerWidth < 1024) {
      let upsellprodHeightMob: any;
      if (elementRef.current && type === 'UP_SELL') {
        upsellprodHeightMob = elementRef?.current.clientHeight;
        if (upsellprodHeightMob > 0) {
          sessionStorage.setItem('upsellclientHeight', upsellprodHeightMob);
        }
      }
      if (elementRef.current && (type === 'RECOMMENDED' || 'BEST_VALUE')) {
        const upsellclientHeight = sessionStorage.getItem('upsellclientHeight');
        if (Number(upsellclientHeight) > 0) {
          elementRef.current.style.height = `${upsellclientHeight}px`;
        }
      }
    }
  }, []);

  useEffect(() => {
    const upsellProduct = document.getElementById(`product-offers-price-UP_SELL`);
    let productHeight = `${upsellProduct?.clientHeight}px`;
    const recommenedProduct = document.getElementById(`product-offers-price-RECOMMENDED`);
    const bestvaluedProduct = document.getElementById(`product-offers-price-BEST_VALUE`);
    if (recommenedProduct && window.innerWidth > 1024) {
      recommenedProduct.style.minHeight = productHeight;
    }
    if (bestvaluedProduct && window.innerWidth > 1024) {
      bestvaluedProduct.style.height = productHeight;
    }
  }, [type]);

  const freeProductId = bundle.order.orderTotals?.find(ot => ot.className === 'ot_free_product')?.freeProductId;
  const freeProducts = bundle.order.orderProducts?.filter(p => p.productId === freeProductId);
  const nsbOneoffFee = bundle.order.orderProducts?.filter(
    p => p.product.sku == productSkuIds.arrowUpfrontBoxFee.primary
  );
  const podOneoffFee = bundle.order.orderProducts?.filter(p => p.product.sku == productSkuIds.skyPod.primary);
  const has01292 = bundle.coupons?.some(c => c.couponCode === couponCodes.offer50WOShohoHindi);

  const _label = () => {
    switch (type) {
      case 'BEST_VALUE':
        return 'Our Value Package';
      case 'RECOMMENDED':
        return 'Recommended Package';
      case 'UP_SELL':
        return 'Ultimate Package';
    }
  };

  const starterPrice = bundle.order.orderProducts?.find(p => p.product.sku === productSkuIds.starter.primary)?.price;
  const isPod = bundle.order.orderProducts.find(p => p.product.sku === productSkuIds.skyPod.primary);
  const dishRequired = !isPod;
  const hasRecordingCapability = bundle.order.orderProducts.find(
    p => p.product.sku === productSkuIds.arrowBoxRecording.primary
  );

  const _coverImage = () => {
    if (journeyType !== JourneyType.BROADBAND_ONLY) {
      if (bundle.order.orderProducts.find(p => p.product.sku === productSkuIds.arrowBox.primary)) {
        return 'https://static.sky.co.nz/sky/box/arrow-box-16-9.png';
      } else if (bundle.order.orderProducts.find(p => p.product.sku === productSkuIds.skyBoxCharge.primary)) {
        return 'https://static.sky.co.nz/sky/box/sky-box-16-9.png';
      } else if (isPod) {
        return 'https://static.sky.co.nz/sky/box/sky-stream-16x9.png';
      }
    } else if (journeyType === JourneyType.BROADBAND_ONLY) {
      switch (bundle.order.orderProducts.find(p => p.categoryId === categoryIds.broadband)?.product.sku) {
        case productSkuIds.broadbandLightningFastWiFi.primary:
          return 'https://static.sky.co.nz/sky/broadband/img/fibrepro.png';
        case productSkuIds.broadbandWifi100.primary:
          return 'https://static.sky.co.nz/sky/broadband/img/fibreeveryday.png';
        case productSkuIds.broadbandEssnFibre.primary:
          return 'https://static.sky.co.nz/sky/broadband/img/Essential-Fibre-Boost.png';
      }
    }

    // If nothing found
    return 'https://sky.co.nz/documents/117005/1143552/HP_Starter_Collage_Feb_2022.jpg/37664352-a479-286f-6af7-002d4758ae9e?t=1643880635013';
  };

  const _title = () => {
    let title = '';
    if (journeyType !== JourneyType.TV_ONLY && fibreStatus !== BroadbandReady.FibreNever) {
      const product = bundle.order.orderProducts.find(p => p.categoryId === categoryIds.broadband)?.product.name;
      title = title + product;
    }
    return title;
  };

  const _price = () => {
    let title = '$';
    if (journeyType !== JourneyType.TV_ONLY && fibreStatus !== BroadbandReady.FibreNever) {
      const product = bundle.order.orderProducts.find(p => p.categoryId === categoryIds.broadband)?.product.price1;
      title = `${title}${product}${product?.toString().includes('.') ? '' : '.00'}/mth`;
    }
    return title;
  };
  const _discount_price = () => {
    let title1 = '$';
    if (journeyType !== JourneyType.TV_ONLY && fibreStatus !== BroadbandReady.FibreNever) {
      const product_listed = bundle.order.orderProducts?.find(p => p.categoryId === categoryIds.broadband)
        ?.finalPriceIncTax;
      // const product_listed = bundle.order.orderProducts?.find(p => p.categoryId === categoryIds.broadband)?.product
      //   .price0;
      const product_current = bundle.order.orderProducts?.find(p => p.categoryId === categoryIds.broadband)?.product
        .price1;
      const discount = (product_listed || 0) - (product_current || 0);
      title1 = `${title1}${discount}/mth`;
    }
    return title1;
  };

  const offerApplied = (freeProducts && freeProducts.length > 0) || (bundle.coupons && bundle.coupons.length > 0);

  const oneOffProductsWithoutBBOneOffFee = bundle.order?.orderProducts.filter(
    p => p.categoryId === categoryIds.oneOffFee || p.categoryId === categoryIds.broadbandTechnicianFee
  );

  let oneOffFee =
    oneOffProductsWithoutBBOneOffFee.length &&
    oneOffProductsWithoutBBOneOffFee.reduce((prev, curr) => {
      return (prev += curr.price);
    }, 0);
  const nsboneofffee830c = oneOffProductsWithoutBBOneOffFee.filter(p => p.product.sku === '830C');
  if (nsboneofffee830c?.length) {
    oneOffFee = oneOffFee / 2;
  }

  const handleExplore = () => {
    toggleModal();
    trackQuiz(
      QuizEvents.QUIZ_INTERACTION,
      QuizTitles.RECOMMENDATIONS,
      QuizInteractionTypes.BUTTON_CLICK,
      QuizInteractionDetails.EXPLORE,
      '',
      '100%',
      type,
      getRecommendedProducts(bundle)
    );
  };
  const recordingfiltered = bundle.order.orderProducts.filter(p => p.categoryId === categoryIds.recording);

  if (!bundle || !bundle.order.orderProducts.length) return <span />;

  return (
    <>
      <div
        className={`${containerClasses} w-310 rounded-lg flex flex-col border p-6`}
        style={{
          background: containerStyle ? containerStyle : '',
        }}>
        <div>
          <MarketingRectangle
            top={-40}
            left={0}
            mRectColor={'radial-gradient(100% 100% at 0% 0%, #F26522 0%, #F96DB1 100%)'}
            mRectTag={_label()}
          />

          {/* Title. */}
          <div className="sky-h2-black my-3">
            <div>
              {journeyType !== JourneyType.TV_ONLY && fibreStatus !== BroadbandReady.FibreNever
                ? 'Sky TV + '
                : 'Sky TV'}
            </div>
            <div>{_title()}</div>
          </div>

          {/* Offers */}
          {offerApplied && (
            <div ref={elementRef} id={`product-offers-price-${type}`}>
              <p className="sky-h6-black pb-1 mb-1">Offer Applied</p>
              {/* {bundle.coupons?.map(product => ( */}
              <RunningTotalOffer
                // key={product.id}
                // coupon={product}
                key={bundle.coupons[0].id}
                coupon={bundle.coupons[0]}
                offer={undefined}
                captionless
                additionalClasses="text-white"
              />
              {/* ))} */}
              {freeProducts &&
                bundle.coupons.every(c => c.couponCode !== couponCodes.oneMonthOnUsWithSport) &&
                freeProducts.length > 0 &&
                freeProducts?.map(product => (
                  <RunningTotalOffer coupon={undefined} offer={product} captionless key={product.productId} />
                ))}

              {/* T&Cs. */}
              {nsbOneoffFee?.length > 0 && has01292 && (
                <>
                  <p
                    className="sky-h7-reg text-white pb-2 items-center justify-between max-w-80%"
                    data-testid={`running-total-offer-hindi-channels`}>
                    <span className="flex items-center">
                      <span
                        className="icon-wrapper mr-3"
                        style={{
                          minWidth: '15px',
                          width: '15px',
                          height: '15px',
                          display: 'inline-block',
                          cursor: 'pointer',
                          color: '#9100D4',
                        }}>
                        <CheckCircle />
                      </span>
                      {'New Sky Box on us'}
                    </span>
                  </p>
                </>
              )}
              {/* for pod */}
              {podOneoffFee?.length > 0 && has01292 && (
                <>
                  <p
                    className="sky-h7-reg text-white pb-2 items-center justify-between max-w-80%"
                    data-testid={`running-total-offer-hindi-channels`}>
                    <span className="flex items-center">
                      <span
                        className="icon-wrapper mr-3"
                        style={{
                          minWidth: '15px',
                          width: '15px',
                          height: '15px',
                          display: 'inline-block',
                          cursor: 'pointer',
                          color: '#9100D4',
                        }}>
                        <CheckCircle />
                      </span>
                      {'Pod on us'}
                    </span>
                  </p>
                </>
              )}
              <span>
                {' '}
                <a
                  className="underline cursor-pointer mt-3 sky-h8-reg text-12px text-center"
                  href="https://www.sky.co.nz/offer-terms"
                  target="_blank"
                  rel="noopener noreferrer">
                  Offer T&amp;Cs
                </a>{' '}
                apply
              </span>
            </div>
          )}
          <br />
          {/* Pricing. */}
          <div className="pb-0 pt-4 w-full flex flex-col ">
            <div className="flex flex-wrap relative w-full">
              <div className="text-sm mb-2">From</div>

              <div className="w-full mb-1 ml-3">
                <div className="inline-block">
                  <FancyPrice
                    price={Number(bundle.order.orderTotals.find(t => t.className === 'ot_subtotal')?.value ?? 0)}
                    numberStyles="md:sky-h2-black sky-h2 font-bold w-full text-md"
                    containerClasses="relative"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* One-off fee. */}

          {/* <div
            className={oneOffFee > 0 ? 'bg-white p-1 width-fit h-9' : 'invisible' + ' h-24-9'}
            style={{
              borderRadius: '4px',
              transform: 'skew(-10deg)',
              transformOrigin: '0 0',
            }}>
            <div
              className="text-skyPurple text-center sky-h7-bold"
              style={{
                wordBreak: 'keep-all',
                whiteSpace: 'nowrap',
                background: 'white',
                borderRadius: '4px',
                transform: 'skew(10deg)',
                transformOrigin: '0 0',
              }}>
              {oneOffFee > 0 ? `Plus, a $${oneOffFee}/one-off charge` : ''}
            </div>
          </div> */}
        </div>
        {/* Buttons. */}
        <div className="btn-container flex">
          {/* Details. */}
          {!shouldHideExplore && (
            <Button
              onClick={handleExplore}
              variant="primary"
              colorMode="light"
              className="details-quiz mr-0 ml-0 min-w-auto items-start flex-grow">
              {QuizInteractionDetails.EXPLORE}
            </Button>
          )}
          {/* Get Started. */}
          <Button
            onClick={() => {
              trackQuiz(
                QuizEvents.QUIZ_INTERACTION,
                QuizTitles.RECOMMENDATIONS,
                QuizInteractionTypes.BUTTON_CLICK,
                QuizInteractionDetails.GET_STARTED,
                '',
                '100%',
                type,
                getRecommendedProducts(bundle)
              );
              if (user?.given_name) {
                addToast({
                  title: 'Alert',
                  message:
                    "The recommendation is for new to Sky customers only, if you're already a Sky Customer, login to My Account for the best offers available to you.",
                  type: 'warning',
                  action: () => redirectToSubdomain('MY_ACCOUNT'),
                  actionText: 'Login My Account',
                  closeText: 'Close',
                });
              } else {
                /* Analytics. */
                bundle.order.orderProducts.forEach(p => {
                  productAdded(p.product, false);
                });

                redirectToSubdomain(
                  'ICE',
                  `/build-my-plan/checkout?sid=${bundle.order.customerId}&addressId=${address.addressId ?? ''}`
                );
              }
            }}
            variant="primary"
            colorMode="pure"
            className={`mr-0 min-w-auto items-end flex-grow ${!shouldHideExplore ? 'getstarted-quiz ml-4' : 'ml-0'}`}>
            {QuizInteractionDetails.GET_STARTED}
          </Button>
        </div>

        <span className="block w-full border-b border-gray-lightestBorder mt-4" />

        {/* Box. */}
        <div className="text-center py-4 w-full">
          <ul id={`bundle-product-list_${type}`} className={`flex flex-col flex-wrap gap-5 sky-h7-reg`}>
            {bundle.order.orderProducts
              .filter(
                p =>
                  /* Display box product below. */
                  p.categoryId === categoryIds.box
              )
              .sort((a, b) => {
                return categorySort.indexOf(a.categoryId) - categorySort.indexOf(b.categoryId);
              })
              .map(p => (
                <li className="flex items-center">
                  <span
                    className="icon-wrapper mr-3 text-green"
                    style={{
                      width: '17px',
                      height: '17px',
                      display: 'inline-block',
                      cursor: 'pointer',
                      color: '#8DC63F',
                    }}>
                    <CheckCircle />
                  </span>
                  <span className="sky-h7-black">{p.product.name}</span>
                </li>
              ))}
            {/* Recording. */}
            {recordingfiltered.length > 0 ? (
              bundle.order.orderProducts
                .filter(
                  p => p.categoryId === categoryIds.recording && p.product.sku !== productSkuIds.noRecording.primary
                )
                .sort((a, b) => {
                  return categorySort.indexOf(a.categoryId) - categorySort.indexOf(b.categoryId);
                })
                .map(p => (
                  <li className="flex items-center">
                    <span
                      className="icon-wrapper mr-3 text-green"
                      style={{
                        width: '17px',
                        height: '17px',
                        display: 'inline-block',
                        cursor: 'pointer',
                        color: '#8DC63F',
                      }}>
                      <CheckCircle />
                    </span>
                    {
                      <span className="sky-h7-reg text-left">
                        {bundle.order.orderProducts.find(p => p.product.sku === productSkuIds.skyBoxCharge.primary)
                          ? '500GB My Sky recording included'
                          : '1TB My Sky recording included'}
                      </span>
                    }
                  </li>
                ))
            ) : (
              <div style={{ height: '18px' }}></div>
            )}
          </ul>
        </div>

        <div className="rounded-lg overflow-hidden">
          <img
            className="w-full h-full rounded-xl block object-contain object-center"
            alt={`${type} Bundle`}
            src={_coverImage()}
          />
        </div>

        {/* My Sky. */}
        <div className={`text-white py-3 items-center`}>
          <div className={`flex flex-row items-center ${!isPod ? 'pb-24px' : ''}`}>
            <p className="pr-2">{dishRequired ? <DartDishIcon color="white" /> : <DartDishIconStrikeThrough />}</p>
            <div className={`sky-h7-bold flex flex-row items-center `}>
              {dishRequired ? (
                <>
                  {' '}
                  <p className="whitespace-no-wrap">Requires Dish</p>
                  <span className="sky-sub flex flex-wrap ml-2">Free standard installation</span>{' '}
                </>
              ) : (
                'No Dish Required '
              )}
            </div>
          </div>
          {/*Remove 'Recording capability' icon, only display No Recording: */}
          <div className={`flex flex-row items-center ${!dishRequired ? 'pt-1' : ''}`}>
            {!dishRequired && (
              <>
                <p className="pr-4">{<NoRecordingIcon />}</p>
                <p className="sky-h7-bold">{'No Recording Capability'}</p>
              </>
            )}
            {/* <p className="pr-4">{dishRequired ? <RecordingIcon /> : <NoRecordingIcon />}</p>
            <p className="sky-h7-bold">{dishRequired ? 'Recording Capability' : 'No Recording Capability'}</p>*/}
          </div>
        </div>

        <span className={`block w-full border-b border-gray-lightestBorder `} />

        {/* TV Packages. */}
        <div className="flex flex-grow">
          <div className="py-2 w-full">
            <div className="sky-h7-black py-3">TV Packages</div>
            <ul id={`bundle-product-list_${type}`} className={`flex flex-col flex-wrap gap-2 sky-h7-reg`}>
              {bundle.order.orderProducts
                .filter(
                  p =>
                    /* Display TV Packages product below. */
                    p.categoryId === categoryIds.package ||
                    p.categoryId === categoryIds.packageUpgrade ||
                    p.categoryId === categoryIds.special ||
                    p.categoryId === categoryIds.hindiChannels
                )
                .sort((a, b) => {
                  return categorySort.indexOf(a.categoryId) - categorySort.indexOf(b.categoryId);
                })
                .map(p => (
                  <li className="flex items-center">
                    <span
                      className="icon-wrapper mr-3 text-green"
                      style={{
                        width: '17px',
                        height: '17px',
                        display: 'inline-block',
                        cursor: 'pointer',
                        color: '#8DC63F',
                      }}>
                      <CheckCircle />
                    </span>
                    <span className="text-center ">{p.product.name}</span>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        {/* Broadband. */}
        {journeyType !== JourneyType.TV_ONLY && fibreStatus !== BroadbandReady.FibreNever && (
          <div>
            <span className="block w-full border-b border-gray-lightestBorder mt-6" />
            <div className="sky-h7-black py-4">Broadband</div>
            <ul id={`bundle-product-list_${type}`} className={`flex flex-col flex-wrap gap-2 sky-h7-reg`}>
              {bundle.order.orderProducts
                .filter(
                  p =>
                    /* Display Broadband product below. */
                    p.categoryId === categoryIds.broadband
                )
                .sort((a, b) => {
                  return categorySort.indexOf(a.categoryId) - categorySort.indexOf(b.categoryId);
                })
                .map(p => (
                  <li>
                    <div className="flex ">
                      <span
                        className="icon-wrapper mr-3 text-green"
                        style={{
                          width: '17px',
                          height: '17px',
                          display: 'inline-block',
                          cursor: 'pointer',
                          color: '#8DC63F',
                        }}>
                        <CheckCircle />
                      </span>
                      <span className="text-center lh-18  ">{p.product.name}</span>
                    </div>
                    <div className="text-xs pl-8 lh-16">{p.product.custom8}</div>
                  </li>
                ))}
            </ul>
          </div>
        )}
        <span className="block w-full border-b border-gray-lightestBorder my-6" />
        <p className={`text-xs text-left ${journeyType !== JourneyType.TV_ONLY ? 'min-h-102' : ''}`}>
          {`Sky Starter is $${starterPrice}/mth${
            journeyType !== JourneyType.TV_ONLY && fibreStatus !== BroadbandReady.FibreNever
              ? ` and ${_title()} Broadband is ${_price()} with this bundle.${
                  _title() !== 'Fibre Starter' && _title() !== 'Fibre Starter Boost'
                    ? ` Save ${_discount_price()} off Sky Broadband. `
                    : ''
                }`
              : '.'
          } 50% off Sky TV discount applies when you join with Sky Starter on a 12 month contract.`}
          <span>
            {' '}
            <a
              className="underline cursor-pointer mt-3 sky-h8-reg text-12px text-center"
              href="https://www.sky.co.nz/offer-terms"
              target="_blank"
              rel="noopener noreferrer">
              Offer T&amp;Cs
            </a>{' '}
            and exit fees apply.
          </span>
        </p>
      </div>

      {/* One off products. */}
      {/* {oneOffProductsWithoutBBOneOffFee && oneOffProductsWithoutBBOneOffFee.length > 0 && (
            <div className="pt-4 px-5 mb-6">
              <p className="sky-h6-black pb-1 mb-3 text-navy">One-off Charges</p>
              {oneOffProductsWithoutBBOneOffFee.map(oneOffProduct => (
                <RunningTotalProduct
                  konakartService={konakartService}
                  product={oneOffProduct}
                  key={oneOffProduct.productId}
                  removable={oneOffProduct.categoryId !== categoryIds.broadbandOneOffFee}
                />
              ))}
            </div>
          )} */}

      <BundleExploreModal isShown={showModal} bundle={bundle} hide={toggleModal} subscribed={true} title={_label()} />
    </>
  );
};

export default BundleCard;

/* Sort products in a bundle card. */
export const categorySort = [
  categoryIds.package,
  categoryIds.box,
  categoryIds.recording,
  categoryIds.packageUpgrade,
  categoryIds.special,
  categoryIds.broadband,
  categoryIds.broadbandOneOffFee,
  categoryIds.broadbandDevices,
];
