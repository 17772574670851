import React, { useEffect } from 'react';
import cx from 'classnames';
import { Route, useLocation } from 'react-router-dom';
import { useQuizJourneyState } from '../../../provider/QuizJourney';
import { QuizRoute, QuizStep, StepTransitionType } from '../../../types';
import QuizNavigation from '../QuizNavigation';
import InterestedInStep from '../steps/InterestedInStep';
import RecommendationsStep from '../steps/RecommendationsStep';
import StepWrapper from '../StepWrapper';
import { QuizEvents, QuizInteractionTypes, useAnalytics } from '@sky-tv-group/shared';
import { QuizTitles } from '../../../constants';

const QuestionaireWrapper = () => {
  const { score, updateScore, quizJourney, stepTransition } = useQuizJourneyState();
  const location = useLocation();
  const wrapperProperties = cx('w-full h-full transform transition ease-in-out duration-200', {
    'opacity-1 translate-x-0': stepTransition === StepTransitionType.NONE,
    'opacity-0 translate-x-24': stepTransition === StepTransitionType.ENTER,
    'opacity-0 -translate-x-24': stepTransition === StepTransitionType.LEAVE,
  });

  const { trackQuiz } = useAnalytics();

  useEffect(() => {
    trackQuiz(QuizEvents.QUIZ_LAUNCHED, QuizTitles.INTEREST, QuizInteractionTypes.LAUNCH, '', '', '', '', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!score || !updateScore) return <p>Loading</p>;

  const quizSteps: QuizRoute[] = [
    {
      slug: '/',
      title: QuizTitles.INTEREST,
      step: QuizStep.INTEREST,
      component: InterestedInStep,
    },
    ...(quizJourney?.routes ?? []),
    {
      slug: '/recommendations',
      title: 'Here are our recommended packages for you',
      step: QuizStep.RECOMMENDATIONS,
      component: RecommendationsStep,
    },
  ];

  return (
    <>
      <div className={wrapperProperties}>
        {quizSteps.map(step => (
          <Route path={step.slug} exact={true} component={(_: any) => <StepWrapper step={step} />} />
        ))}
      </div>
      <QuizNavigation step={quizSteps.find(s => s.slug === location.pathname)!} />
    </>
  );
};

export default QuestionaireWrapper;
