import {
  categoryIds,
  CouponTypeValue,
  formatDate,
  formatMoney,
  KonakartService,
  productSkuIds,
  T_OrderProduct,
  T_Product,
  useBroadbandSwapProduct,
  useCartContainer,
  useCoupon,
  useHandleRemove,
  useProductInCart,
  useRemoveProduct,
  useRunningTotal,
} from '@sky-tv-group/shared';
import { RunningTotalOffer, RunningTotalProduct, CheckCircle, ChevronDown, ChevronUp } from '@sky-tv-group/components';
import React, { useState } from 'react';

interface Props {
  show?: boolean;
  konakartService: KonakartService;
}
const RunningTotal = ({ show = true, konakartService }: Props) => {
  const [active, setActiveState] = useState('block');
  const [buttonIcon, setButtonIconState] = useState('-');
  const [mobileButtonIcon, setMobileButtonIconState] = useState(false);

  function toggle() {
    setActiveState(active === 'hidden' ? 'block' : 'hidden');
    setButtonIconState(buttonIcon === '+' ? '-' : '+');
    setMobileButtonIconState(mobileButtonIcon ? false : true);
  }

  const {
    starter,
    tvPackageProducts,
    addOnProducts,
    acquisitionSkyBoxProducts,
    acquisitionMultiroomProducts,
    broadbandProducts,
    voiceProducts,
    voiceAddonsProducts,
    coupons,
    freeProducts,
    productsHindiChannels,
  } = useRunningTotal(konakartService);

  const { oneOffProductsWithoutBBOneOffFee, broadbandDevices } = useProductInCart();
  const { getOfferEndsText, getOldestOfferEndDate } = useCoupon();
  const { monthlyFees, orderTotals } = useCartContainer(konakartService, CouponTypeValue.Acquisition);
  const { canRemoveFromCart } = useRemoveProduct(konakartService, CouponTypeValue.Acquisition);
  const handleRemove = useHandleRemove(konakartService);
  const { meshDevice, removeMesh, meshAdded } = useBroadbandSwapProduct(konakartService);

  // Taken out from RunningTotalProduct.tsx to avoid multiple re-renders on that component
  const removeProduct = async (product: T_Product) => {
    const isWifiBooster = product.sku === productSkuIds.meshDevice.primary;
    const isWifiRouter = product.sku === productSkuIds.skyRouter.primary;

    if (isWifiRouter) {
      if (meshAdded) removeMesh(meshDevice!);
      await handleRemove(product);
    } else if (isWifiBooster) {
      await removeMesh(meshDevice!);
    } else {
      await handleRemove(product);
    }
  };

  // ot_subtotal, ot_total
  const [totalPerMonth, promotionalTotalPerMonth] = orderTotals.map(t => formatMoney(t?.value ?? 0));
  const allOffersEndDate = getOldestOfferEndDate(coupons, monthlyFees, null);

  const offerApplied =
    (freeProducts && freeProducts.length > 0) ||
    (coupons && coupons.length > 0) ||
    (productsHindiChannels && productsHindiChannels.length > 1);

  if (!show) return null;

  return (
    <div
      className="flex flex-col md:flex-col bg-white right-0 border-l border-b lg:h-screen"
      data-testid="running-total">
      <div className="lg:sticky" style={{ top: 60 }}>
        <h4
          onClick={toggle}
          className="sky-h5 text-left py-4 px-10 flex lg:hidden items-center justify-between font-bold"
          style={{ borderTop: '3px solid #ccc', borderBottom: '1px solid #ccc', color: '#0057FF' }}>
          <span>{mobileButtonIcon ? 'Show Summary' : 'Hide Summary'}</span>
          <span style={{ width: '15px', height: '15px', display: 'inline-block', color: '#0057FF' }}>
            {mobileButtonIcon ? (
              <>
                <ChevronUp />
              </>
            ) : (
              <>
                <ChevronDown />
              </>
            )}
          </span>
        </h4>
        <div className={active + ' lg:block md:package-list pt-6'}>
          <div className="flex flex-col md:flex-row pb-4">
            <div className="w-full mb-4">
              <div className="flex-1 mx-4 md:mx-0">
                <div className="flex-col divide-y border-b border-gray-200">
                  {/* TV Packages */}
                  {starter && (
                    <div className="pt-4 px-5 mb-6">
                      <p className="sky-h6-black pb-1 mb-3 text-navy">TV Packages</p>

                      {/* Starter. */}
                      {starter && (
                        <RunningTotalProduct
                          product={starter}
                          removable={false}
                          included
                          removeProduct={removeProduct}
                        />
                      )}

                      {tvPackageProducts &&
                        tvPackageProducts.length > 0 &&
                        tvPackageProducts?.map(product => (
                          <RunningTotalProduct
                            product={product}
                            key={product.productId}
                            removeProduct={removeProduct}
                          />
                        ))}
                    </div>
                  )}
                  {/* Add ons */}
                  {addOnProducts && addOnProducts.length > 0 && (
                    <div className="pt-4 px-5 mb-6">
                      <p className="sky-h6-black pb-1 mb-3 text-navy">Premium Channels</p>
                      {addOnProducts?.map(product => (
                        <RunningTotalProduct
                          product={product}
                          key={product.productId}
                          removable={canRemoveFromCart(product.product)}
                          removeProduct={removeProduct}
                        />
                      ))}
                    </div>
                  )}
                  {/* Sky Box */}
                  {acquisitionSkyBoxProducts && acquisitionSkyBoxProducts.length > 0 && (
                    <div className="pt-4 px-5 mb-6">
                      <p className="sky-h6-black pb-1 mb-3 text-navy">Sky Box</p>
                      {acquisitionSkyBoxProducts?.map(product => (
                        <RunningTotalProduct
                          product={product}
                          key={product.productId}
                          removable={false}
                          removeProduct={removeProduct}
                        />
                      ))}

                      {/* Sky Multiroom Boxes */}
                      {acquisitionMultiroomProducts &&
                        acquisitionMultiroomProducts.length > 0 &&
                        acquisitionMultiroomProducts?.map(product => (
                          <RunningTotalProduct
                            product={product}
                            key={product.productId}
                            removeProduct={removeProduct}
                          />
                        ))}
                    </div>
                  )}

                  {/* Broadband */}
                  {broadbandProducts && broadbandProducts.length > 0 && (
                    <div className="pt-4 px-5 mb-6">
                      <p className="sky-h6-black pb-1 mb-3 text-navy">Broadband</p>
                      {broadbandProducts?.map(product => (
                        <RunningTotalProduct product={product} key={product.productId} removeProduct={removeProduct} />
                      ))}

                      {/* Devices. */}
                      {broadbandDevices &&
                        broadbandDevices.length > 0 &&
                        broadbandDevices?.map((product: T_OrderProduct) => (
                          <RunningTotalProduct
                            product={product}
                            key={product.productId}
                            removeProduct={removeProduct}
                          />
                        ))}

                      {/* Voice. */}
                      {voiceProducts &&
                        voiceProducts.length > 0 &&
                        voiceProducts?.map((product: T_OrderProduct) => (
                          <RunningTotalProduct
                            product={product}
                            key={product.productId}
                            removeProduct={removeProduct}
                          />
                        ))}

                      {/* Voice Addons. */}
                      {voiceAddonsProducts &&
                        voiceAddonsProducts.length > 0 &&
                        voiceAddonsProducts?.map((product: T_OrderProduct) => (
                          <RunningTotalProduct
                            product={product}
                            key={product.productId}
                            removeProduct={removeProduct}
                          />
                        ))}
                    </div>
                  )}
                  {/* Offers */}
                  {offerApplied && (
                    <div className="pt-4 px-5 mb-6">
                      <p className="sky-h6-black pb-1 mb-3" style={{ color: '#9100D4' }}>
                        Offer Applied
                      </p>
                      {coupons?.map(product => (
                        <RunningTotalOffer
                          key={product.id}
                          coupon={product}
                          offer={undefined}
                          offerText={getOfferEndsText(product, monthlyFees, null, true)}
                        />
                      ))}
                      {freeProducts &&
                        freeProducts.length > 0 &&
                        freeProducts?.map(product => (
                          <RunningTotalOffer
                            coupon={undefined}
                            offer={product}
                            offerText={''}
                            key={product.productId}
                          />
                        ))}
                      {productsHindiChannels && productsHindiChannels.length > 1 && (
                        <>
                          <p
                            className="sky-h7-reg text-navy pb-2 items-center justify-between max-w-80%"
                            data-testid={`running-total-offer-hindi-channels`}>
                            <span className="flex items-center">
                              <span
                                className="icon-wrapper mr-3"
                                style={{
                                  width: '15px',
                                  height: '15px',
                                  display: 'inline-block',
                                  cursor: 'pointer',
                                  color: '#9100D4',
                                }}>
                                <CheckCircle />
                              </span>
                              {'Hindi Channels Bundle'}
                            </span>
                            <span className="sky-sub inline-block pl-7">
                              {'Bundle any two Hindi language channels for $14.99/mth or all three for $19.99/mth'}
                            </span>
                          </p>
                        </>
                      )}
                    </div>
                  )}
                  {/* One off products. */}
                  {oneOffProductsWithoutBBOneOffFee && oneOffProductsWithoutBBOneOffFee.length > 0 && (
                    <div className="pt-4 px-5 mb-6">
                      <p className="sky-h6-black pb-1 mb-3 text-navy">One-off Charges</p>
                      {oneOffProductsWithoutBBOneOffFee.map(oneOffProduct => (
                        <RunningTotalProduct
                          product={oneOffProduct}
                          key={oneOffProduct.productId}
                          removable={oneOffProduct.categoryId !== categoryIds.broadbandOneOffFee}
                          removeProduct={removeProduct}
                        />
                      ))}
                    </div>
                  )}

                  <div className="pt-4 px-5 mb-6 text-navy">
                    <p className="sky-h7-reg pb-2 flex items-start justify-between">
                      <span>
                        Package Price
                        <br />
                        {offerApplied && <span className="sky-sub">With offer applied</span>}
                      </span>
                      <span>{promotionalTotalPerMonth}/mth</span>
                    </p>
                    {allOffersEndDate != null && (
                      <p className="sky-h7-reg pb-2 flex items-start justify-between">
                        <span>
                          Package Price
                          <br />
                          <span className="sky-sub">From {formatDate(allOffersEndDate)}</span>
                        </span>
                        <span>{totalPerMonth}/mth</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pr-3 mx-8 ml-10 block lg:hidden">
          <p className="sky-h4-black my-5 flex items-start justify-between" style={{ color: '#0057FF' }}>
            <span>Monthly Plan</span>
            <span>{totalPerMonth}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export { RunningTotal };
