import { CheckCircle } from '@sky-tv-group/components';
import { categoryIds, productSkuIds } from '@sky-tv-group/shared';
import React from 'react';
import { SUGGESTED_BUNDLE } from '../../../../../../types';
import { categorySort } from '../BundleCard';

interface Props {
  bundle: SUGGESTED_BUNDLE;
}

const Stats = ({ bundle }: Props) => {
  return (
    <ul className="w-full flex flex-row items-center justify-center bg-gray-lightest border-b border-gray-lightestBorder p-4 overflow-x-auto">
      {bundle.order.orderProducts
        .filter(
          p =>
            /* Don't display products below. */
            p.product.sku !== productSkuIds.skyRouter.primary &&
            p.categoryId !== categoryIds.oneOffFee &&
            p.categoryId !== categoryIds.broadbandTechnicianFee &&
            p.categoryId !== categoryIds.monthlyFee &&
            p.categoryId !== categoryIds.broadbandServices &&
            p.categoryId !== categoryIds.broadbandOneOffFee &&
            p.categoryId !== categoryIds.broadbandDevices &&
            p.categoryId !== categoryIds.technicianFee
        )
        .sort((a, b) => {
          return categorySort.indexOf(a.categoryId) - categorySort.indexOf(b.categoryId);
        })
        .map(p => (
          <li className="flex items-center mx-2 whitespace-no-wrap">
            <span
              className="icon-wrapper mr-3 text-green"
              style={{
                width: '17px',
                height: '17px',
                display: 'inline-block',
                cursor: 'pointer',
                color: '#8DC63F',
              }}>
              <CheckCircle />
            </span>
            <span className="sky-h7-black uppercase">{p.product.name}</span>
          </li>
        ))}
    </ul>
  );
};

export default Stats;
