import { QuizTitles } from '../constants';
import { QuizStep, SUGGESTED_BUNDLE } from '../types';

export const sortArray = (x: any, y: any) => {
  var pre = ['string', 'number', 'bool'];
  if (typeof x !== typeof y) return pre.indexOf(typeof y) - pre.indexOf(typeof x);

  if (x === y) return 0;
  else return x > y ? 1 : -1;
};

export const getTitle = (step: QuizStep | undefined) => {
  switch (step) {
    case QuizStep.INTEREST:
      return QuizTitles.INTEREST;
    case QuizStep.TV:
      return QuizTitles.WATCH;
    case QuizStep.APPS:
      return QuizTitles.APPS;
    case QuizStep.RECOMMENDATIONS:
      return QuizTitles.RECOMMENDATIONS;
    case QuizStep.WIFI_USE:
      return QuizTitles.WIFI_USE;
    case QuizStep.MAXIMUM_NUMBER_OF_PEOPLE:
      return QuizTitles.MAXIMUM_NUMBER_OF_PEOPLE;
    case QuizStep.WIFI_BOOST:
      return QuizTitles.WIFI_BOOST;
    default:
      return '';
  }
};

export const getRecommendedPackage = (
  suggestedBundles: [SUGGESTED_BUNDLE | null, SUGGESTED_BUNDLE | null, SUGGESTED_BUNDLE | null]
) => {
  const numOfBundles = suggestedBundles.filter(b => b?.order.orderProducts.length).length;
  return numOfBundles > 2
    ? 'Our Value Package/Recommended Package/Ultimate Package'
    : 'Recommended Package/Ultimate Package';
};

export const getRecommendedProducts = (suggestedBundle: SUGGESTED_BUNDLE | null) => {
  return suggestedBundle?.order?.orderProducts
    .map(p => {
      return p.product.name;
    })
    .toString();
};
