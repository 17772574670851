import { AddressPrequalification, T_Product, ReduxDevTools, StatePersistence } from '@sky-tv-group/shared';
import create from 'zustand';

interface AddressLines {
  line1: string;
  line2: string;
  line3: string;
  line4: string;
}

interface StoreData {
  authenticatedPrequalData: AddressPrequalification | undefined;
  guestPrequalData: AddressPrequalification | undefined;
  isLoadingPrequal: boolean;
  selectedCardIndex: number;
  useGuestPrequal: boolean;
  selectedInstallationAddress: string | undefined;
  selectedTuiAddress: string | undefined;
  selectedAddressId: string | undefined;
  authTuiAddress: string | undefined;
  broadbandProductSelected: T_Product | undefined;
  isBundle: boolean;
  hasActiveOrPendingBroadbandPackage: boolean;
  selectedAddressLines: AddressLines | undefined;
  redirectFromCheckingPage: boolean;
}

const sessionStorageStateName = 'Broadband SPA Store';
const getSessionStorageState: StoreData = JSON.parse(sessionStorage.getItem(sessionStorageStateName) ?? '{}');
const clearSessionStorageState = () => {
  sessionStorage.setItem(sessionStorageStateName, '{}');
};

// Converts prequal json data to AddressPrequalification objects
const transform = (storageState: StoreData) => {
  Object.keys(storageState).forEach(key => {
    if (key === 'authenticatedPrequalData' || key === 'guestPrequalData') {
      storageState[key] = new AddressPrequalification(storageState[key]?.prequal);
    }
  });
  return storageState;
};

interface Store extends StoreData {
  setAuthenticatedPrequal: (prequal: AddressPrequalification) => void;
  setGuestPrequal: (prequal: AddressPrequalification) => void;
  setIsLoading: (value: boolean) => void;
  setUseGuestPrequal: (value: boolean) => void;
  setSelectedInstallationAddress: (value: string | undefined, selectedAddressLines: AddressLines) => void;
  setSelectedTuiAddress: (value: string | undefined) => void;
  setSelectedAddressId: (value: string | undefined) => void;
  setAuthTuiAddress: (value: string | undefined) => void;
  setBroadbandProductSelected: (value: T_Product) => void;
  setIsBundle: (value: boolean) => void;
  clearStore: () => void;
  clearPersistedButtonStates: () => void;
  persistToStorage: () => void;
  setSelectedCardIndex: (value: number) => void;
}

let [useStore, storeApi] = create<Store>(
  ReduxDevTools(
    // @ts-ignore
    (set, get) => ({
      authenticatedPrequalData: undefined,
      guestPrequalData: undefined,
      selectedInstallationAddress: '',
      selectedTuiAddress: '',
      selectedAddressId: '',
      authTuiAddress: '',
      selectedAddressLines: undefined,
      broadbandProductSelected: undefined,
      isBundle: false,
      hasActiveOrPendingBroadbandPackage: false,
      redirectFromCheckingPage: false,
      ...(transform(getSessionStorageState) as {}),

      // booleans that should'nt be persisted in storage
      isLoadingPrequal: false,
      useGuestPrequal: getSessionStorageState.useGuestPrequal ?? false,
      selectedCardIndex: 1,
      setAuthenticatedPrequal: (prequal: AddressPrequalification) => {
        set({ authenticatedPrequalData: prequal });
      },
      setGuestPrequal: (prequal: AddressPrequalification) => {
        set({ guestPrequalData: prequal });
      },
      setIsLoading: (isLoading: boolean) => {
        set({ isLoadingPrequal: isLoading });
      },
      setUseGuestPrequal: (value: boolean) => {
        set({ useGuestPrequal: value });
      },
      setSelectedInstallationAddress: (value: string | undefined, selectedAddressLines: AddressLines) => {
        set({ selectedInstallationAddress: value, selectedAddressLines });
      },
      setSelectedTuiAddress: (value: string | undefined) => {
        set({ selectedTuiAddress: value });
      },
      setSelectedAddressId: (value: string | undefined) => {
        set({ selectedAddressId: value });
      },

      setAuthTuiAddress: (value: string | undefined) => {
        set({ authTuiAddress: value });
      },

      setBroadbandProductSelected: (value: T_Product) => {
        set({ broadbandProductSelected: value }, `Product selected ${value.sku}`);
      },

      setIsBundle: (value: boolean) => {
        set({ isBundle: value });
      },

      setSelectedCardIndex: (value: number) => {
        set({ selectedCardIndex: value });
      },

      clearStore: () => {
        set({
          guestPrequalData: undefined,
          selectedInstallationAddress: '',
        });
      },
      persistToStorage: () => {
        const state = get();
        set({ ...state });
      },
    }),
    'Get Started SPA Store'
  )
);

let set = storeApi.setState;
export { useStore, set, storeApi, clearSessionStorageState };
