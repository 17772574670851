import { Checked, Unchecked } from '@sky-tv-group/components';
import React, { useRef, useState } from 'react';
import cx from 'classnames';
import { useQuizJourneyState } from '../../../../provider/QuizJourney';
import { QuizItem } from '../../../../types';

interface Props {
  item: QuizItem;
  action: Function;
}

const TvCard = ({ item, action }: Props) => {
  const { selectedItems } = useQuizJourneyState();
  const [videoSource, toggleVideoSource] = useState('');
  const classes = cx({
    'border-skyPurple border-2': selectedItems[item.id],
    border: !selectedItems[item.id],
  });

  const vidRef = useRef<HTMLVideoElement>(null);

  const handlePlayVideo = () => {
    toggleVideoSource(item.video!);
  };

  return (
    <button
      className={`w-full min-h-32 bg-white border border-gray-lightBorder rounded-lg flex flex-col items-center justify-center relative cursor-pointer transition ease-in-out duration-200 p-4 hover:border-skyPurple ${classes}`}>
      <button
        className="absolute top-0 left-0 w-full h-full"
        onClick={e => {
          e.preventDefault();
          action();
        }}
      />
      <div className="flex flex-row justify-between w-full mb-2 min-h-50px">
        <div className="">
          <strong className={`font-skyBold text-md`}>{item.title}</strong>
        </div>
        <div>{selectedItems[item.id] ? <Checked /> : <Unchecked />}</div>
      </div>
      <div className={`flex flex-col items-center w-full`}>
        <div className="overflow-hidden rounded-md w-full relative h-40">
          {videoSource.length ? (
            <video ref={vidRef} className="w-full h-40 transition ease-in-out duration-300 z-30" controls autoPlay>
              <source src={videoSource} type="video/mp4" />
              Your browser does not support HTML video.
            </video>
          ) : (
            <div onClick={!item.video ? () => action() : handlePlayVideo}>
              {item.video && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 block z-10">
                  <PlayButton />
                </div>
              )}
              <img className="absolute top-0 left-0 w-full h-full block z-5" src={item.imageURL} alt="TV card" />
            </div>
          )}
        </div>
        {item.caption && (
          <div className="w-full mt-1 flex items-start">
            <p className="text-xs text-left" dangerouslySetInnerHTML={{ __html: item.caption }} />
          </div>
        )}
        {item.tags && item.tags.length > 0 && (
          <div className="w-full mt-1 flex items-start flex-wrap">
            {item.tags.map(t => (
              <p className="text-xs text-left" dangerouslySetInnerHTML={{ __html: `•&nbsp;${t}&nbsp;` }} />
            ))}
          </div>
        )}
      </div>
    </button>
  );
};

const PlayButton = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 21.75L21 15L12 8.25V21.75ZM15 0C6.72 0 0 6.72 0 15C0 23.28 6.72 30 15 30C23.28 30 30 23.28 30 15C30 6.72 23.28 0 15 0ZM15 27C8.385 27 3 21.615 3 15C3 8.385 8.385 3 15 3C21.615 3 27 8.385 27 15C27 21.615 21.615 27 15 27Z"
        fill="white"
      />
    </svg>
  );
};

export default TvCard;
