import React from 'react';
import { useQuizJourneyState } from '../../../../provider/QuizJourney';
import { QuizRoute } from '../../../../types';
import IconCard from '../../cards/IconCard';

interface Props {
  step: QuizRoute;
}

const WifiUsageStep = ({ step }: Props) => {
  const { updateScore, selectedItems, quizCategories } = useQuizJourneyState();

  if (!updateScore) return <p>Loading</p>;

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-10 w-full">
      {quizCategories
        .filter(c => c.step === step.step)
        .map(c => (
          <IconCard action={() => updateScore(c.id)} label={c.title} icon={c.icon!} selected={selectedItems[c.id]} />
        ))}
    </div>
  );
};

export default WifiUsageStep;
